import { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { PrivateLayout, PublicLayout } from './components/RouteWithLayout';
import './assets/css/general.css';
import './assets/css/swiper.min.css';
import './assets/css/responsive.css';
import { HomeLayout, WelcomeLayout } from './layout';
import ScrollToTop from './components/ScrollToTop';
import { ApiHelpers } from './helpers';
import Landing from './view/Landing';
import Payment from './view/Payment';
import Registration from './view/Registration';
import ResetPassword from './view/ResetPassword';
import ConfirmPassword from './view/ConfirmPassword';
import Campaign from './view/User/Camapaign';
import ManageCampaign from './view/User/ManageCampaign';
import Profile from './view/User/Profile';
import Performance from './view/User/Performance';
import Home from './view/User/Home';
import Login from './view/Login';
import LearnMore from './view/LearnMore';
import './App.css';
import { getUser, resetUserLoading } from './redux/actions';
function App() {
  const dispatch = useDispatch();
  const [dataset,Setdataset] = useState();
  useEffect(async() => {
    ApiHelpers.responseInterceptor();
    ApiHelpers.requestInterceptor();
    const accessToken = localStorage.getItem('accessToken');
    Setdataset(accessToken);
    if (accessToken) {
       dispatch(getUser());
    }
    else {
       dispatch(resetUserLoading());
    }
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <PublicLayout component={Landing} exact path='/home' layout={HomeLayout} />
        <PublicLayout component={Payment} exact path='/payment' layout={WelcomeLayout} />
        <PublicLayout component={LearnMore} exact path='/learn-more' layout={WelcomeLayout} />
        <PublicLayout component={ResetPassword} exact path='/reset-password' layout={WelcomeLayout} />
        <PublicLayout component={ConfirmPassword} exact path='/reset-mail-password/:token' layout={WelcomeLayout} />
        <PublicLayout component={Registration} exact path='/register' layout={WelcomeLayout} />
        <PublicLayout component={Login} exact path='/login' layout={WelcomeLayout} />
        <PrivateLayout component={Home}  exact path='/homeview' layout={WelcomeLayout} />
        <PrivateLayout component={Campaign} data={dataset} exact  path='/campaign' layout={WelcomeLayout} />
        <PrivateLayout component={Campaign} data={dataset}  path='/campaign/:id' layout={WelcomeLayout} />
        <PrivateLayout component={ManageCampaign} data={dataset} exact path='/manage-campaign' layout={WelcomeLayout} />
        <PrivateLayout component={Profile} data={dataset} exact path='/profile' layout={WelcomeLayout} />
        <PrivateLayout component={Performance} data={dataset} exact path='/performance' layout={WelcomeLayout} />
        <PublicLayout path="*"><Redirect to="/home" /></PublicLayout>
      </Switch>
    </Router>
  );
}
export default App;
