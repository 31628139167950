import React from 'react';
import social_facebook from '../../assets/images/landing/social_facebook.png';
import social_google from '../../assets/images/landing/social_google.png';
import social_linkedin from '../../assets/images/landing/social_linkedin.png';
import social_pinterest from '../../assets/images/landing/social_pinterest.png';
import social_tumblr from '../../assets/images/landing/social_tumblr.png';
import social_twitter from '../../assets/images/landing/social_twitter.png';
const Homefooter = (props) => {
    return (
        <div className="footer_v">
        <div className="footer-widget pb-15 pt-15">
            <div className="container">
                <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 order-md-2 order-lg-1 menu_col">
                          <div className="widget-link">
                             <nav className="nav-footer justify-content-center pt-20"> 
                             <a href="#"><img src={social_twitter} alt="" /></a>
                              <a href="#"><img src={social_facebook} alt="" /></a> 
                             <a href="#"><img src={social_google} alt="" /></a> 
                             <a href="#"><img src={social_tumblr} alt="" /></a> 
                             <a href="#"><img src={social_pinterest} alt="" /></a> 
                             <a href="#"><img src={social_linkedin} alt="" /></a> 
                           </nav>
                          </div> 
                      </div>
                </div> 
            </div>
        </div>
  <div className="footer-copyright copyright pb-15">
    <div className="copyright ">
      <p>Copyright © 2021 WithYou Company. All rights reserved.</p>
    </div>
  </div>
  </div>
    )
}

export default Homefooter;