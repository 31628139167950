import { configureStore } from '@reduxjs/toolkit';
import { userReducer, campaignReducer } from '../reducers';

export const store = configureStore({
  reducer: {
    user: userReducer,
    campaign:campaignReducer
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
});
