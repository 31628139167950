import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiHelpers } from '../../../helpers';
export const loginUser = createAsyncThunk('user/login',async (credentials, { dispatch }) => {
    try {
     const accessToken = await ApiHelpers.postData('/v1/users/me/dashboard_login', credentials);
     if(accessToken.status === "failed")
     {
      return "Invalid User";
     }
     else {
      localStorage.setItem('accessToken', accessToken.with.access_token);
      return dispatch(getUser());
     }
    } catch (e) {
      console.log('User login error: ', e);
    }
  }
);

export const getUser = createAsyncThunk('user/get-user', async (id, { rejectWithValue }) => {
  try {
    let result = await ApiHelpers.getData(`/v1/users/me`);
    if(result.status === "succeeded" || 200)
    {
      return result;
    }
    else
    {
      return "Invalid User";
    }
    
  } catch (e) {
    console.log('user error: ', e);
  }
});

export const resetUserLoading = createAction('user/reset-loading');

export const createUser = createAsyncThunk('user/create',async (credentials, { dispatch }) => {
  try {
   const signupstatus = await ApiHelpers.postData('/v1/users/me/create-user', credentials);
   if(signupstatus.status === "failed" && signupstatus.with === 401)
    {
      return signupstatus.because;
    }
    else if(signupstatus.status === "failed" && signupstatus.with === 400)
    {
      return signupstatus.with;
    }
    else if(signupstatus.status === "failed" && signupstatus.with === 403)
    {
      return signupstatus.because;
    }
    else {
      return signupstatus.status;
    }
  } catch (e) {
    console.log('User Create error: ', e);
  }
}
);


export const updateUser = createAsyncThunk('user/profile',async (credentials, { dispatch }) => {
  try {
   const signupstatus = await ApiHelpers.postUserForm(`/v1/users/profile`, credentials);
   if(signupstatus.status === "failed") {
      return signupstatus.because;
    }
    else {
      return signupstatus.status;
    }
  } catch (e) {
    console.log('User Profile error: ', e);
  }
}
);


export const otpverifyUser = createAsyncThunk('user/otpverify',async (credentials, { dispatch }) => {
  try {
    const accessToken = await ApiHelpers.postData('/v1/users/me/verify', credentials);
    localStorage.setItem('accessToken', accessToken.with.access_token);
    dispatch(postUser(credentials.signup_values));
  } catch (e) {
    console.log('User login error: ', e);
  }
}
);


export const postUser = createAsyncThunk('user/postUser',async (credentials, { dispatch }) => {
  try {
    const signupstatus = await ApiHelpers.postData('/v1/users/me', credentials);
    localStorage.setItem('postDatas',"saved");
  } catch (e) {
    console.log('User post error: ', e);
  }
}
);


export const resetPasswordUser = createAsyncThunk('user/resetpassword',async (credentials, { dispatch }) => {
  try {
   const result_success = await ApiHelpers.postData('/v1/users/reset-password-request', credentials);
    if(result_success.status === "failed") {
      return result_success?.status;
    }
    else {
    return result_success?.status;
    }
  } catch (e) {
    console.log('User reset error: ', e);
  }
}
);

// Reset Password - Verify
export const resetVerifyUser = createAsyncThunk('user/verify-user', async (id, { rejectWithValue }) => {
  try {
    const verifyToken =  await ApiHelpers.postData(`/v1/users/verfify-password-token`,{token:id.verify_token.token});
    if(verifyToken.status === "failed") {
      return "Expired Link";
    }
    else{
      return verifyToken?.data?.with?.token;
    }
  } catch (e) {
    console.log('user error: ', e);
  }
});

// Confirm Password - Update
export const confirmPasswordUser = createAsyncThunk('user/confirmpassword',async (confirm, { dispatch }) => {
  try {
   const result = await ApiHelpers.postData('/v1/users/reset-password', confirm);
    return result?.status;
  } catch (e) {
    console.log('User confirm error: ', e);
  }
}
);


// Profile Password - Update
export const profilePasswordUser = createAsyncThunk('user/profilepassword',async (confirm, { dispatch }) => {
  try {
   const result = await ApiHelpers.postData('/v1/users/change-password', confirm);
    return result?.status;
  } catch (e) {
    console.log('User profile error: ', e);
  }
}
);


//Validate Email from Mobile App - Upgrade
export const verifyMail = createAsyncThunk('user/validateemail',async (emailid, { dispatch }) => {
  try {
   const signupstatus = await ApiHelpers.postData('/v1/users/me/validate-user-email', {email:emailid});
   if(signupstatus.status === "failed" && signupstatus.with === 401)
    {
      return signupstatus.because;
    }
    else if(signupstatus.status === "failed" && signupstatus.with === 400)
    {
      return signupstatus.with;
    }
    else if(signupstatus.status === "failed" && signupstatus.with === 403)
    {
      return signupstatus.because;
    }
    else {
      return signupstatus.status;
    }
  } catch (e) {
    console.log('User Verification error: ', e);
  }
}
);

//upgradeUser

export const upgradeUser = createAsyncThunk('user/upgrade',async (credentials, { dispatch }) => {
  try {
   const signupstatus = await ApiHelpers.postData('/v1/users/me/upgrade-user', credentials);
   if(signupstatus.status === "failed" && signupstatus.with === 401)
    {
      return signupstatus.because;
    }
    else if(signupstatus.status === "failed" && signupstatus.with === 400)
    {
      return signupstatus.with;
    }
    else {
      return signupstatus.status;
    }
  } catch (e) {
    console.log('User Create error: ', e);
  }
}
);