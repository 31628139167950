import React, { useRef, useEffect, useState, forwardRef } from 'react';
import useGoogleMapsApi from "../AutoCompleteGeo";
const LocationInput = forwardRef((props, ref) => {
  const inputRef = useRef();
  const autocompleteRef = useRef();
  const googleMapsApi = useGoogleMapsApi();
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [placeId, setPlaceid] = useState("");
  const [editable, setEditable] = useState(false);
  const [multiLoc, setMultiLoc] = useState([]);

  useEffect(() => {
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(inputRef.current, { region: ['(US)'] })
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        setName(place?.name);
        setLat(place?.geometry?.location?.lat);
        setLong(place?.geometry?.location?.lng);
        setAddress(place?.formatted_address);
        setPlaceid(place?.place_id);
        props.parentCallback(place?.name,place?.formatted_address, place?.geometry?.location?.lat, place?.geometry?.location?.lng,place?.place_id);
      })
    }
  }, [googleMapsApi]);

  const handleSubmit = (e) => {
    e.preventDefault();
    return false;
  }

  const handleChange = (e) => {
    setAddress(e.target.value);
    setEditable(true);
  }

  const handleMultiple = (e) => {
    setAddress(e.target.value);
    setEditable(true);
  }

  const handleMultiple2 = (e) => {
    multiLoc.push(e.target.value);
    setAddress(e.target.value);
    setEditable(true);
  }

  return (
    <div className='location'>
      {props.multiple === "false" ?
        <form autoComplete='off' className="clsMargin" onSubmit={handleSubmit}>
          <label htmlFor='homeCity'>{props.label}</label>
          {props.edit === "true" && editable === false ?
            <input
              name={props.name}
              aria-label='Search locations'
              ref={inputRef}
              autoComplete='off'
              onChange={handleChange}
              onBlur={props.onBlur}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); return false; }}
              placeholder=""
              id={props.id}
              tabIndex={props.tabindex}
              value={props.val}
            /> : <input
              name={props.name}
              aria-label='Search locations'
              ref={inputRef}
              autoComplete='off'
              onChange={props.handleChange}
              onBlur={props.onBlur}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); return false; }}
              placeholder=""
              id={props.id}
              tabIndex={props.tabindex}
            />}
          <input
            name="homeCityLat"
            type="hidden"
            value={lat}
          />
          <input
            name="homeCityLong"
            type="hidden"
            value={long}
          />
        </form>
        :
        <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 clscampaigncol">
        <div className='multiple_locations clslocationupload'>
          <form autoComplete='off' className="clsMargin" onSubmit={handleSubmit}>
            <label htmlFor='homeCity'>{props.label}</label>
            {props.tooltip ? <div className="cls_tooltip">
            {props.edit === "true" && editable === false ?
              <input
                name={props.name}
                aria-label='Search locations'
                ref={inputRef}
                autoComplete='off'
                onChange={handleChange}
                onBlur={props.onBlur}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); return false; }}
                placeholder=""
                id={props.id}
                tabIndex={props.tabindex}
                value={props.val}
              /> : <input
                name={props.name}
                aria-label='Search locations'
                ref={inputRef}
                autoComplete='off'
                onChange={handleMultiple}
                onBlur={handleMultiple2}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); return false; }}
                placeholder=""
                id={props.id}
                tabIndex={props.tabindex}
              />}
              <span class="tooltiptext">{props.tooltip}</span></div> : ""}
            <input
              name="homeCityLat"
              type="hidden"
              value={lat}
            />
            <input
              name="homeCityLong"
              type="hidden"
              value={long}
            />
          </form>
        </div>
        </div>
        </div>
      }
    </div>
 )
})

export default LocationInput;