import React, { useState, useEffect } from 'react';
import Parallax from "react-parallax-scroll";
import { useHistory, useParams } from "react-router-dom";
import { slider_active, slider_default, slider_midactive, uploadimg, uploadback, white_arrow, campaign_success, manage_next, manage_previous } from '../../assets/images/landing';
import Usernav from '../../components/Navbar/Usernav';
import InputField from '../../components/Input';
import Select from '../../components/Select';
import { Formik, useField, useFormikContext } from 'formik';
import { campaignStepOneValidation } from '../../constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../components/Button';
import { useDispatch } from 'react-redux';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { getUser, createCampaign, getCampaignDetails, updateCampaign, createPlace, deletePlace, getPlaces } from '../../redux/actions';
import LocationInput from '../../components/LocationInput';

const DatePickerField = ({ ...props }) => {
    const { setFieldValue, validateField } = useFormikContext();
    const [field] = useField(props);
    return (
        <div className='clsDatePicker'>
            <label className='clsDatePickerlabel'>{props.label}</label>
            <DatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val) => {
                    setFieldValue(field.name, val);
                }}
                placeholderText="mm/dd/yy"
                className={props.cssclass}
                tabIndex={props.tabindex}
                value={props.value}
                autoComplete='off'
                minDate={props?.startDate ? props?.startDate : ""}
                popperPlacement="top-end"
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
            />
            {props.error && <div className="error_input">{props.error}</div>}
        </div>
    );
};

const Campaign = (props) => {
    const dispatch = useDispatch();
    const [currentStep, SetcurrentStep] = useState(1);
    const [preview, setPreview] = useState([]);
    const [images, setImages] = useState([]);
    const [clearimages, setClearimages] = useState([]);
    const [imageUrl, setImageUrl] = useState([]);
    const [firstStep, setFirststep] = useState();
    const [secondStep, setSecondstep] = useState();
    const [userAddress, setUserAddress] = useState();
    const [imageLen, setImageLen] = useState("");
    const [initial, setInitial] = useState({});
    const [errorImage, setErrorimage] = useState(false);
    const history = useHistory();
    const [campaignId, setCampaignId] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [successLoader, setSuccessLoader] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(false);
    const [removeimages, setRemoveImages] = useState([]);
    const [receievedimages, setReceivedImages] = useState();
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [toggleMultiple, setToggleMultiple] = useState(false);
    const [homeCityval, setHomecityval] = useState("");
    const [homeCityLatval, setHomecityLatval] = useState("");
    const [homeCityLongval, setHomecityLongval] = useState("");
    const [homeCityName, setHomecityName] = useState("");
    const [homeCityPlaceId, setHomecityPlaceId] = useState("");
    const [homeCityErr, sethomeCityErr] = useState(1);
    const [locationval, setLocationval] = useState();
    const [multipleLocations, setMultipleLocations] = useState([]);
    const [placeId, setPlaceid] = useState([]);
    const [selectedLoc, setSelectedLoc] = useState([]);
    const [isTick, setisTick] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState();
    // const [aspectRatioError, setAspectRatioError] = useState('initial'); // 'initial', 'error', 'ok'

    let { id } = useParams();
    useEffect(async () => {
        SetcurrentStep(1);
        setCampaignId(id);
        setImages([]);
        setImageLen("");
        setPreview([]);
        setClearimages([]);
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            let datas = await dispatch(getUser());
            if (datas?.payload?.with?.homeCity !== undefined) {
                setUserAddress(datas?.payload?.with?.homeCity);
                localStorage.setItem("userAddress", datas?.payload?.with?.homeCity);
            }
            if (id) {
                let getCampaigndata = await dispatch(getCampaignDetails(id));
                let getCampaigndataset = getCampaigndata?.payload?.with;
                if (getCampaigndata?.payload?.status === "failed") {
                    setErrorMessage(getCampaigndata?.payload?.because);
                }
                else {
                    setInitial({ title: getCampaigndataset?.title, startDate: moment(getCampaigndataset?.startDate).format('L'), endDate: moment(getCampaigndataset?.endDate).format('L'), category: getCampaigndataset?.category, keywords: getCampaigndataset?.keywords === null ? "0" : getCampaigndataset?.keywords });
                    setImages([getCampaigndataset?.images]);
                    setClearimages([getCampaigndataset?.images]);
                    setReceivedImages(getCampaigndataset?.images);
                    setUserAddress(filterArrayItems(getCampaigndataset?.places));
                    setSelectedLoc(getCampaigndataset?.places);
                    let images = [];
                    getCampaigndataset?.images.map((e) => {
                        images.push(e.url);
                    })
                    getCampaigndataset?.places.map((e) => {
                        placeId.push(JSON.stringify(e.id));
                    })
                    setPreview(images);
                    setErrorimage(false);
                }
            }
            else {
                setInitial({ title: '', startDate: '', endDate: '', category: '', keywords: '' });
            }
            getLocation();
        }
    }, [])

    const createLocation = (pname, paddress, plat, plong, pplaceid) => {
        setHomecityName(pname);
        setHomecityval(paddress);
        setHomecityLatval(plat);
        setHomecityLongval(plong);
        setHomecityPlaceId(pplaceid);
    };

    const postLocation = async () => {
        if (homeCityName !== "") {
            let placeData = { name: homeCityName, address: homeCityval, lat: homeCityLatval, lon: homeCityLongval, googlePlaceId: homeCityPlaceId };
            let result = await dispatch(createPlace(placeData));
            if (result?.payload?.status === "succeeded") {
                getAfterLocation();
                setSuccessLoader(true);
                document.getElementById("location_input").value = "";
                setHomecityName("");
                setHomecityval("");
                setHomecityLatval("");
                setHomecityLongval("");
                setHomecityPlaceId("");
            }
            else {
                if (result?.payload?.because.includes("Duplicate entry")) {
                    setImageLen("Location already saved");
                    setSuccessLoader(false);
                }
                else {
                    setImageLen("Please choose valid address");
                    setSuccessLoader(false);
                }
            }
        }
        else {
            setImageLen("Please choose valid address");
            return false;
        }
    };


    const delete_Modal = (offerId) => {
        setDeleteModalOpen(true);
        setDeleteId(offerId);
    }

    const places_List = (name,address) => {
        let var_address = address;
        let var_businessname = name;
        let comma_seperator = ", ";
        if(var_address.includes(var_businessname)){
            return var_address;
        }
        else{
            return var_businessname+comma_seperator+var_address;
        }
    }

    const removeLocation = async (id) => {
        let location_id = id;
        let result = await dispatch(deletePlace(location_id));
        let val = placeId;
        val = val.filter(item => item !== location_id);
        setPlaceid(val);
        if (result?.payload?.status === "succeeded") {
            getLocation();
            setImageLen("Deleted successfully");
            setSuccessLoader(true);
            setDeleteModalOpen(false);
        }
        else {
            setImageLen("Delete failed - please try later");
            setSuccessLoader(false);
            setDeleteModalOpen(false);
        }
    }

    const getAfterLocation = async () =>{
        let getLocationdata = await dispatch(getPlaces());
        let getCampaigndataset = getLocationdata?.payload?.with;
        setImageLen("");
        if (getCampaigndataset?.payload?.status === "failed") {
            setErrorMessage(getCampaigndataset?.payload?.because);
        }
        else {
                const values1 = [...multipleLocations];
                const values2 = [...getCampaigndataset];
                const results = values2.filter(({ id: id1 }) => !values1.some(({ id: id2 }) => id2 === id1));
                const new_location = results.map(v => ({ ...v, ischeck: true }));
                placeId.push(new_location[0].id);
                multipleLocations.push(...new_location);
                setSuccessLoader(false);
        }
    }

    const getLocation = async () => {
        let getLocationdata = await dispatch(getPlaces());
        let getCampaigndataset = getLocationdata?.payload?.with;
        setImageLen("");
        if (getCampaigndataset?.payload?.status === "failed") {
            setErrorMessage(getCampaigndataset?.payload?.because);
        }
        else {
            if (id) {
                let objIndex;
                const new_location = await getCampaigndataset.map(v => ({ ...v, ischeck: false }));
                let results = await new_location.filter(function (o1) {
                    return selectedLoc.some(function (o2) {
                        return o1.id === o2.id;
                    });
                });
                if (results.length > 0) {
                    results.map((datas) => {
                        objIndex = new_location.findIndex((obj => obj.id === datas.id));
                        new_location[objIndex].ischeck = true;
                    });
                    setisTick(isTick + 1);
                }
                if(homeCityval !== "")
                {
                    let objIndex = new_location.findIndex((obj => obj.address === homeCityval));
                    new_location[objIndex].ischeck = true;
                    placeId.push(new_location[objIndex].id);
                    setisTick(isTick + 1);
                }
                setMultipleLocations(new_location);
                setSuccessLoader(false);
            }
            else {
                const new_location = getCampaigndataset.map(v => ({ ...v, ischeck: false }));
                if(homeCityval !== "")
                {
                    let objIndex = new_location.findIndex((obj => obj.address === homeCityval));
                    new_location[objIndex].ischeck = true;
                    placeId.push(new_location[objIndex].id);
                    setisTick(isTick + 1);
                }
                setMultipleLocations(new_location);
                setSuccessLoader(false);
            }
        }
    };

    const slideNext = () => {
        document.getElementById("previewimage1").style.display = "block";
        document.getElementById("previewimage0").style.display = "none";
    }

    const slidePrevious = () => {
        document.getElementById("previewimage1").style.display = "none";
        document.getElementById("previewimage0").style.display = "block";
    }


    const handleSubmit = async (values) => {
        const userAdd = localStorage.getItem('userAddress');
        values.placeAddress = userAddress?.name === null || undefined ? userAdd?.name : userAddress?.name;
        values.placeName = userAddress?.name === null || undefined ? userAdd?.name : userAddress?.name;
        values.placeLat = userAddress?.lat === null || undefined ? userAdd?.lat : userAddress?.lat;
        values.placeLon = userAddress?.lon === null || undefined ? userAdd?.lon : userAddress?.lon;
        values.placeGooglePlaceId = userAddress?.name === null || undefined ? userAdd?.name : userAddress?.name;
        values.availability = "1, 1, 1, 1, 1, 1, 1";
        setFirststep(values);
        nextStep();
    };


    const handleSubmit_Image = async (e) => {
        setImageLen("");
        e.preventDefault();
        if (clearimages.length > 0) {
            let secondStepVal = firstStep;
            secondStepVal["file"] = clearimages;
            secondStepVal["startDate"] = moment(secondStepVal["startDate"]).format('yyyy-MM-DD') + 'T00:00:00.000Z';
            secondStepVal["endDate"] = moment(secondStepVal["endDate"]).format('yyyy-MM-DD') + 'T00:00:00.000Z';
            setSecondstep(secondStepVal);
            nextStep_location();
        }
        else {
            setImageLen("Upload Images are required");
            setErrorimage(false);
            setSuccessLoader(false);
            return false;
        }
    };


    const handleSubmit_Location = async (e) => {
        setImageLen("");
        e.preventDefault();
        let key  = 'ischeck';
        let isTick_len = multipleLocations.filter(o=> key in o && o[key] === true).length;
        if (placeId.length > 0 && multipleLocations.length > 0 && isTick_len >= 1) {
            setSuccessLoader(true);
            let locationStepVal = secondStep;
            let uniquearr = [...new Set(placeId)];
            locationStepVal.placeIds = uniquearr.toString();
            let result = await dispatch(createCampaign(locationStepVal));
            if (result?.payload?.status === "succeeded") {
                nextStep2();
                setSuccessLoader(false);
            }
            else {
                setImageLen("Please check all the values");
                setPreview([]);
                setImages([]);
                setClearimages([]);
                setSuccessLoader(false);
            }
        }
        else {
            setImageLen("Please select business locations");
            return false;
        }
    };

    const initialize = () => {
        document.body.onfocus = checkIt;
    }

    // RE: Chrome - Browse Cancel 
    const checkIt = () => {
        if (document.getElementById('image_upload').value.length) {
            //  console.log(images.length);
        }
        else {
            let arrayofFiles = [];
            let blobofFiles = [];
            let imageshold = clearimages;
            for (var i = 0; i < imageshold?.length; i++) {
                var extension = imageshold[i]?.type;
                if (extension.includes("application")) {
                    setImageLen("Please try with upload image formats only");
                    return false;
                }
                else {
                    if (imageshold[i]?.size > 5242880) {
                        setImageLen("Upload file size is too big - Please try less than 5 mb");
                        return false;
                    }
                    else {
                        arrayofFiles.push(imageshold[i]);
                        setImageLen("");
                    }
                }
            }
            arrayofFiles.map((e) => {
                const ImageUrl = URL.createObjectURL(e);
                blobofFiles.push(ImageUrl);
            })
            setPreview(blobofFiles);
            setImages(clearimages);
        }
        document.body.onfocus = null;
    }

    const imageValidate = () => {
        setImageLen("Please upload only two images");
        setSuccessLoader(false);
        return false;
    }

    const handleUpdate = async (e) => {
        setImageLen("");
        e.preventDefault();
        let key  = 'ischeck';
        let isTick_len = multipleLocations.filter(o=> key in o && o[key] === true).length;
        if (placeId.length > 0 && multipleLocations.length > 0 && isTick_len >= 1) {
            setSuccessLoader(true);
            const userAdd = localStorage.getItem('userAddress');
            let locationStepVal = secondStep;
            if (clearimages.length > 0) {
                locationStepVal["startDate"] = moment(locationStepVal["startDate"]).format('yyyy-MM-DD') + 'T00:00:00.000Z';
                locationStepVal["endDate"] = moment(locationStepVal["endDate"]).format('yyyy-MM-DD') + 'T00:00:00.000Z';
                if(removeimages.length === 1 && receievedimages.length === 2) {
                    locationStepVal["file"] = images;
                }
                else if(removeimages.length === 0 && receievedimages.length === 1) {
                    locationStepVal["file"] = images;
                }
                else if(removeimages.length === 1 && receievedimages.length === 1) {
                    locationStepVal["file"] = clearimages; 
                }
                else if(removeimages.length === 2) {
                    locationStepVal["file"] = clearimages; 
                }
                else {
                    locationStepVal["file"] = clearimages; 
                }
                locationStepVal["updated"] = updatedImage;
                locationStepVal["removeImages"] = removeimages;
                let uniquearr = [...new Set(placeId)];
                locationStepVal["placeIds"] = uniquearr.toString();
                if (receievedimages.length >= 2 && removeimages.length === 1 && images.length > 1) {
                    return imageValidate();
                }

                else if (receievedimages.length >= 2 && removeimages.length === 2 && images.length > 2) {
                    return imageValidate();
                }

                else if (receievedimages.length === 0 || images.length === 0 || preview.length === 0) {
                    setImageLen("Upload Images are required");
                    setSuccessLoader(false);
                    return false;
                }

                else {
                    let result = await dispatch(updateCampaign({ id: campaignId, values: locationStepVal }));
                    if (result?.payload?.status === "succeeded") {
                        nextStep2();
                        setSuccessLoader(false);
                    }
                    else {
                        setImageLen("Record not saved - Please try once again");
                        setPreview([]);
                        setImages([]);
                        setClearimages([]);
                        setSuccessLoader(false);
                    }
                }
            }
            else {
                setImageLen("Upload Images are required");
                setErrorimage(false);
                setSuccessLoader(false);
                return false;
            }
        }
        else {
            setImageLen("Please select any location");
            setErrorimage(false);
            setSuccessLoader(false);
            return false;
        }
    };

    const nextStep = () => {
        SetcurrentStep(2);
    }
    const nextStep_location = () => {
        SetcurrentStep(3);
        getLocation();
    }
    const nextStep2 = () => {
        SetcurrentStep(4);
        setInitial({ title: '', startDate: '', endDate: '', category: '', keywords: '' });
    }
    const backStep = () => {
        SetcurrentStep(1);
        setInitial({ title: firstStep.title, startDate: firstStep.startDate, endDate: firstStep.endDate, category: firstStep.category, keywords: firstStep.keywords });
    }
    const backStep2 = () => {
        SetcurrentStep(2);
        setSuccessLoader(false);
    }

    function isWithinAspectRatio(actualRatio, targetWidth, targetHeight) {
        const epsilon = 0.01; // A small margin of error to account for rounding differences.
        const targetRatio = targetWidth / targetHeight;
        return Math.abs(actualRatio - targetRatio) < epsilon;
    }

    const onSelectFile = async (e) => {
        let arrayofFiles = [];
        let blobofFiles = [];
        let images_select = [];
        let totalimages = 0;
        let aspectRatioValid = true;

        if ((receievedimages?.length >= 2 && removeimages?.length === 0 && e.target.files.length > 0) ||
        (receievedimages?.length >= 2 && removeimages?.length === 1 && e.target.files.length > 1) ||
        (receievedimages?.length >= 2 && removeimages?.length === 2 && e.target.files.length > 2) ||
        (receievedimages?.length === 1 && removeimages?.length === 1 && e.target.files.length > 2) ||
        (receievedimages?.length === 1 && removeimages?.length === 0 && e.target.files.length >= 2)) {
            return imageValidate();
        }
        else {
            // debugger;
            if (e.target.files.length < 2) {
                // const len = e.target.files.length;
                for await (let file of e.target.files) {
                    var extension = file.type;
                    if (extension.includes("application")) {
                        setImageLen("Please try with upload image formats only");
                        return false;
                    }
                    else {
                        if (file.size > 5242880) {
                            setImageLen("Upload file size is too big - Please try less than 5 mb");
                            return false;
                        }
                        // console.log({file:file})
                        // if (file_) {
                        const img = new Image();
                        img.src = URL.createObjectURL(file);
                        await new Promise(resolve=>{
                            img.onload = async function() {
                                const ancho = img.width;
                                const alto = img.height;
                                
                                const aspectRatio = ancho / alto;
        
                                if (isWithinAspectRatio(aspectRatio, 1, 1) ||
                                    isWithinAspectRatio(aspectRatio, 5, 4) ||
                                    isWithinAspectRatio(aspectRatio, 16, 9) ||
                                    isWithinAspectRatio(aspectRatio, 9, 16)) {
                                } else {
                                    setImageLen("Sorry! This image size is not supported! Please crop it or use one with any of these aspect ratios: Square 1:1, Landscape 16:9, Portrait 9:16, Slide 5:4");
                                    // setAspectRatioError('error');
                                    aspectRatioValid = false;
                                    return false;
                                }
                                resolve();
                            };
                        });
                        arrayofFiles.push(file);
                        // clearimages.push(file);
                        setClearimages([...clearimages, file])
                        setImageLen("");
                    }
                    if(!aspectRatioValid ){
                        break;
                    }
                    // }
                }
                if(!aspectRatioValid){ return;}
                // console.log("continue->")
                arrayofFiles.map((e) => {
                    const ImageUrl = URL.createObjectURL(e);
                    images_select.push(ImageUrl);
                    blobofFiles.push({ e, ImageUrl });
                })
                setImages(arrayofFiles);
                setImageUrl(blobofFiles);
                totalimages = preview.length + images_select.length;
                if (totalimages > 2) {
                    return imageValidate();
                }
                else if (receievedimages?.length === 1 && removeimages?.length === 1 && totalimages === 1) {
                    setPreview(images_select);
                }
                else if (receievedimages?.length === 1 && removeimages?.length >= 1 && totalimages === 1) {
                    preview.push(images_select);
                }
                else if (receievedimages?.length === 1 && removeimages?.length >= 1 && totalimages === 2 && images.length === 2 && preview.length === 0) {
                    setPreview(images_select);
                }
                else if (receievedimages?.length === 1 && removeimages?.length >= 1 && totalimages === 2 && images.length === 1 && preview.length === 1) {
                    preview.push(images_select);
                }
                else if (receievedimages?.length === 1 && removeimages?.length === 0) {
                    preview.push(images_select);
                }
                else if (receievedimages?.length === 2 && removeimages?.length === 1) {
                    preview.push(images_select);
                }
                else if (receievedimages?.length === 2 && removeimages?.length >= 2 && images_select?.length === 1) {
                    preview.push(images_select);
                }
                else if (images_select?.length === 1 && totalimages === 2) {
                    preview.push(images_select);
                }
                else {
                    setPreview(images_select);
                }
                setErrorimage(false);
                setUpdatedImage(true);
                if (totalimages === 1) {
                    setImageModalOpen(true);
                }
            }
            else {
                setErrorimage(true);
                setPreview([]);
                setImages([]);
                setClearimages([]);
                setUpdatedImage(false);
            }
        }
    }

    const filterUrlItems = (arr, query) => {
        return arr.filter(function (el) {
            return el.ImageUrl !== query;
        })
    }

    const filterItems = (arr, query) => {
        return arr.filter(function (el) {
            return el.url === query;
        })
    }

    const filterIdItems = (arr, query) => {
        let val = arr.filter(function (el) {
            return el.id === query;
        })
        if (val.length > 0) {
            setisTick(1);
            return true;
        }
        else {
            setisTick(0);
            return false;
        }
    }

    const filterArrayItems = (arr) => {
        return arr.filter(function (el) {
            return el.name !== undefined &&
                el.address !== undefined &&
                el.lat !== 0 &&
                el.lon !== 0;
        });
    }

    const openDialog = () => {
        document.getElementById('image_upload').click();
    }


    const onAddingItem = (event, index) => {
        const isChecked = event.target.checked;
        const value = event.target.value;
        const position = index;
        if (value === "all") {
            if (isChecked) {
                const new_location = multipleLocations.map(v => ({ ...v, ischeck: true }))
                setMultipleLocations(new_location);
                multipleLocations?.map((e) => {
                    placeId.push(JSON.stringify(e.id));
                });
                setisTick(multipleLocations.length);
            }
            else {
                const new_location = multipleLocations.map(v => ({ ...v, ischeck: false }))
                setMultipleLocations(new_location);
                setPlaceid([]);
                setisTick(0);
            }
        }
        else {
            if (isChecked === true) {
                const values = [...multipleLocations];
                values[position].ischeck = true;
                setMultipleLocations(values);
                placeId.push(value);
                document.getElementById("checkbox_all").checked = false;
                setisTick(isTick + 1);
            }
            else if (isChecked === false) {
                const values = [...multipleLocations];
                values[position].ischeck = false;
                setMultipleLocations(values);
                let val = placeId;
                val = val.filter(item => item !== value);
                setPlaceid(val);
                document.getElementById("checkbox_all").checked = false;
                setisTick(isTick - 1);
            }
        }
    }

    const removeImageFromArray = (e) => {
        const index = e.target.id;
        let img_name = e.target.getAttribute("name");
        let newPreview = [...preview];
        newPreview.splice(index, 1);
        if (index === "1") {
            document.getElementById("previewimage0").style.display = "block";
        }
        if (campaignId) {
            const result2 = filterItems(receievedimages, img_name);
            setRemoveImages([...removeimages, result2]);
        }
        setPreview(newPreview);
        if (newPreview.length === 0) {
            document.getElementById('image_upload').value = '';
            setImages([]);
            setClearimages([]);
        }
        if (newPreview.length === 1) {
            const result3 = filterUrlItems(imageUrl, img_name);
            if (result3.length > 0) {
                setImages([result3[0].e]);
                setClearimages([result3[0].e]);
            }
        }
    }

    const options = [
        {
            id: 'Dining',
            name: 'Dining'
        },
        {
            id: 'Home Services',
            name: 'Home Services'
        },
        {
            id: 'Health & Wellness',
            name: 'Health & Wellness'
        },
        {
            id: 'Kids',
            name: 'Kids'
        },
        {
            id: 'Pets',
            name: 'Pets'
        },
        {
            id: 'Retail',
            name: 'Retail'
        },
        {
            id: 'Other',
            name: 'Other'
        },
    ];
    return (
        <Parallax>
            <div className='welcome_bg'>
                {/* Campaign Data Form Step 1 */}
                {currentStep && currentStep === 1 && (
                    <div className='campaign_cover'>
                        <header className="header-area header-area ">
                            <Usernav modalView="partialheader" banner_title="Create/Stage Campaign"></Usernav>
                        </header>
                        <div className="container clscampaign_card">
                            {errorMessage ? <div className="clserror_msg">{errorMessage}</div> : (
                                <div className="row col-lg-12 clscampaignrow">

                                    <div className='clscampaign_head'>Only a few steps and you are set to go</div>
                                    <div className='clsslider_view'><img src={slider_active} alt="" /><img src={slider_default} alt="" /><img src={slider_default} alt="" /> <img src={slider_default} alt="" /></div>
                                    <Formik enableReinitialize={true} initialValues={initial}
                                        validationSchema={campaignStepOneValidation}
                                        onSubmit={handleSubmit}>
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isValid
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="row clscampaign_rows">
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 clscampaigncol">
                                                        <InputField name="title" type="text" cssclass="clsinput clsprimary" placeholder="" label="Campaign Title"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values.title} error={errors.title && touched.title ? errors.title : undefined} tabindex='1' />
                                                        <div className="row clscampaign_rows col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 clsnopadding">
                                                                <DatePickerField name="startDate" cssclass="clsprimary clsstartdate" value={values.startDate} label="Start Date" tabindex='3' error={errors.startDate && touched.startDate ? errors.startDate : undefined} />
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 clsnopadding">
                                                                <DatePickerField name="endDate" cssclass="clsprimary clsenddate" startDate={values.startDate} value={values.endDate} label="End Date" tabindex='4' error={errors.endDate && touched.endDate ? errors.endDate : undefined} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 clscampaigncol">
                                                        <Select
                                                            name="category"
                                                            label="Category"
                                                            labelview="Category"
                                                            options={options}
                                                            onChange={handleChange}
                                                            cssclass="clscampaign_select"
                                                            value={values.category}
                                                            status={values.category}
                                                            error={errors.category && touched.category ? errors.category : undefined}
                                                            tabindex='2'
                                                        />
                                                        <InputField name="keywords" type="text" cssclass="clsinput clsprimary" placeholder="" label="Enter Keywords" label2="(seperated by commas)"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values.keywords} error={errors.keywords && touched.keywords ? errors.keywords : undefined} tabindex='5' />
                                                    </div>
                                                </div>
                                                <div className='clscampaignlink clscampaignmargin'>
                                                    <button type='submit'>Next Step <img src={white_arrow} alt="White Arrow" /></button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {/* Image Upload Step 2 */}
                {currentStep && currentStep === 2 && (
                    <div className='campaign_cover'>
                        <header className="header-area header-area ">
                            <Usernav modalView="partialheader" banner_title="Create/Stage Campaign"></Usernav>
                        </header>
                        <div className="container clscampaign_card">
                            <div className="row col-lg-12 clscampaignrow">
                                <div className='clscampaign_head'>Only a few steps and you are set to go</div>
                                <div className='clsslider_view'><img src={slider_midactive} alt="" /><img src={slider_active} alt="" /><img src={slider_default} alt="" /> <img src={slider_default} alt="" /></div>
                                {/* <form onSubmit={campaignId ? handleUpdate : handleSubmit_Image}> */}
                                <form onSubmit={handleSubmit_Image}>
                                    <div className="row clscampaign_rows">
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <div className="clsimgupload">
                                                <div className='clsuploadimg'>
                                                    {toggleMultiple === true ? <input id="image_upload" type="file" name="file" accept=".gif,.jpg,.jpeg,.png" multiple className='hideClass 1' onClick={initialize} onChange={onSelectFile} />
                                                        : <input id="image_upload" type="file" name="file" accept=".gif,.jpg,.jpeg,.png" className='hideClass 2' onClick={initialize} onChange={onSelectFile} />}
                                                    <img src={uploadimg} alt="" /></div>
                                                <div className='clsuploadhead'>Upload Creative</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                            <div className='clsimgupload'>
                                                {preview.map((img, index) =>
                                                (
                                                    <div key={index} id={"previewimage" + index} style={{ display: preview.length >= 2 && index === 1 ? "none" : "" }} className={"img_preview img_preview_" + preview.length}>
                                                        <img src={img} className="images" id={index} alt="pic1" />
                                                        <button
                                                            className='clsimg_close'
                                                            id={index}
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeImageFromArray(e);
                                                            }}
                                                            name={img}
                                                        >
                                                            x
                                                        </button>

                                                        {index === 0 && preview.length >= 2 ? <button
                                                            className='clsimg_next'
                                                            name={img}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                slideNext(e);
                                                            }}
                                                        >
                                                            <img src={manage_next} alt="pic1" />
                                                        </button> : ""}

                                                        {index === 1 && preview.length >= 2 ? <button
                                                            className='clsimg_previous'
                                                            id={index}
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                slidePrevious(e);
                                                            }}
                                                            name={img}
                                                        >
                                                            <img src={manage_previous} alt="pic1" />
                                                        </button> : ""}

                                                    </div>
                                                )
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                                        {imageLen ? <div className='clsErrorName'>{imageLen}</div> : ""}
                                    </div>
                                    <div className='clscampaignlink clscampaignmargin clscampaignimage'>
                                        {(errorImage === true ? <div className='clsErrorImage'> Please upload maximum 2 images only</div> : "")}
                                        <button className='no_btn' onClick={backStep}><img src={uploadback} alt="" /></button>
                                        <button type="submit">Next Step <img src={white_arrow} alt="White Arrow" /></button>
                                    </div>
                                </form>
                                <Modal
                                    dialogClassName='image-modal-content modal-dialog modal-dialog-centered contact_modal_inn'
                                    centered
                                    show={imageModalOpen}
                                    animation={true}
                                    onHide={() => { setImageModalOpen(false); }}
                                    id="image_modal"
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <div className='contact-modal-contain'>
                                        <div className='modal-header'>
                                            <div className="section-title mt-35">
                                                Does your document have 2 sides ?
                                            </div>
                                        </div>
                                        <div className='modal-body'>
                                            <div className='row image_btn_cancel'>
                                                <button type="button" className='clsyes_btn' onClick={(e) => { e.preventDefault(); setToggleMultiple(true); setImageModalOpen(false); openDialog() }}><span aria-hidden="true">Yes</span></button>
                                                <button type="button" data-dismiss="modal" aria-label="Close" onClick={() => { setImageModalOpen(false); }}><span aria-hidden="true">No</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                )}
                {/* Location Step 3 */}
                {currentStep && currentStep === 3 && (
                    <div className='campaign_cover'>
                        <header className="header-area header-area ">
                            <Usernav modalView="partialheader" banner_title="Create/Stage Campaign"></Usernav>
                        </header>
                        <div className="container clscampaign_card">
                            {errorMessage ? <div className="clserror_msg">{errorMessage}</div> : (
                                <div className="row col-lg-12 clscampaignrow">

                                    <div className='clscampaign_head'>Only a few steps and you are set to go</div>
                                    <div className='clsslider_view'><img src={slider_midactive} alt="" /><img src={slider_midactive} alt="" /><img src={slider_active} alt="" /><img src={slider_default} alt="" /></div>
                                    <Formik enableReinitialize={true} initialValues={initial}
                                        validationSchema={campaignStepOneValidation}
                                        onSubmit={handleSubmit}>
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isValid
                                        }) => (
                                            <form onSubmit={campaignId ? handleUpdate : handleSubmit_Location}>
                                                <div className="row clscampaign_rows">
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 clscampaigncol">
                                                        <LocationInput name="homeCity" id="location_input" parentCallback={createLocation} edit="true" val={locationval} label="Add New Location" multiple="true" tooltip="Please enter with Business Name & Locations"
                                                            onChange={e => { setFieldValue('homeCity', homeCityval); sethomeCityErr(1) }}
                                                            onBlur={e => { setFieldValue('homeCity', homeCityval); sethomeCityErr(1) }} tabindex='6' />
                                                        {homeCityErr === 2 ? <div className="error_input error_prof_address"> Required </div> : ""}
                                                        {homeCityErr === 3 ? <div className="error_input error_prof_address"> Please Choose Exact Location</div> : ""}
                                                        <button type='button' id="btn_loc" onClick={(e) => {e.preventDefault();postLocation();}}>Enter</button>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 clscampaigncol">
                                                        <div className='multiple_locations clslocationupload'>
                                                            <div className='clslocation_head'>
                                                                <div className="cls_locationheading">Business Name & Locations</div>
                                                                <div class="checkbox checkbox-circle checkbox-color-scheme">
                                                                    <label class="checkbox-checked">
                                                                        <input type="checkbox" id="checkbox_all" value="all" onChange={onAddingItem} />
                                                                    </label>
                                                                    <span>All Locations</span>
                                                                </div>
                                                                {multipleLocations.map((product, i) => {
                                                                    return (
                                                                        <div key={i + 1}>
                                                                            <div class="checkbox checkbox-circle checkbox-color-scheme">
                                                                                <label class="checkbox-checked">
                                                                                    {product.address !== "" ? <input type="checkbox" id={"check_" + product.id} value={product.id} checked={product.ischeck} onChange={(e) => {
                                                                                        onAddingItem(e, i)
                                                                                    }} /> : ""
                                                                                    }
                                                                                </label>
                                                                                <span>{places_List(product.name,product.address)}</span>
                                                                                <button
                                                                                    className='clslocation_close'
                                                                                    id={i}
                                                                                    key={i}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        delete_Modal(product.id);
                                                                                    }}
                                                                                    name={product.id}
                                                                                >
                                                                                    x
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {imageLen ? <div className='clsErrorName'>{imageLen}</div> : ""}
                                                {successLoader ? <div className='campaign-performance-loader'></div> : ""}
                                                <div className='clscampaignlink clscampaignmargin clscampaignlocation'>
                                                    <button className='no_btn' type='button' onClick={backStep2}><img src={uploadback} alt="" /></button>
                                                    <button type='submit'>Next Step <img src={white_arrow} alt="White Arrow" /></button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            )}
                            <Modal
                                dialogClassName='delete-modal-content modal-dialog modal-dialog-centered contact_modal_inn'
                                centered
                                show={deleteModalOpen}
                                animation={true}
                                onHide={() => { setDeleteModalOpen(false); setDeleteId(""); }}
                                id="delete_modal"
                            >
                                <div className='contact-modal-contain'>
                                    <div className='modal-header'>
                                        <div className="section-title mt-35">
                                            Are you sure you want to delete this, click yes
                                        </div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setDeleteModalOpen(false); setDeleteId("") }}><span aria-hidden="true">x</span></button>
                                    </div>
                                    <div className='modal-body'>
                                        <div className='row delete_btn_cancel'>
                                            <button type="button" className='clsyes_btn' onClick={(e) => { e.preventDefault(); removeLocation(deleteId) }}><span aria-hidden="true">Yes</span></button>
                                            <button type="button" data-dismiss="modal" aria-label="Close" onClick={() => { setDeleteId(""); setDeleteModalOpen(false) }}><span aria-hidden="true">No</span></button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                )}

                {/* Success Message Step 4 */}
                {currentStep && currentStep === 4 && (
                    <div className='campaign_cover'>
                        <header className="header-area header-area">
                            <Usernav modalView="partial" banner_title=""></Usernav>
                        </header>
                        <div className="container clscampaign_cardscreen">
                            <div className="row col-lg-12 clscampaignrow">
                                <div className="row clscampaign_rows">
                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"> </div>
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 clscampMargin">
                                        <div className='clssuccessheading'>
                                            Congratulations
                                        </div>
                                        <div className='clssuccesscontent'>
                                            Your campaign has been submitted
                                            and  should be live on the app shortly.
                                            This allows the campaign creative
                                            to run through our AI tool to make
                                            sure it's not offensive in any way.
                                        </div>
                                        <div className='clssuccessimg'>
                                            <img src={campaign_success} alt="" />
                                        </div>
                                        <div className='clscampaignlink clscampaignmargin clscampaignmanage'>
                                            <Button text="Manage Your Promotions" onClick={(e) => { e.preventDefault(); history.push('/manage-campaign') }}></Button>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='clsCircleBg'></div>
            </div>
        </Parallax>
    )
}

export default Campaign;