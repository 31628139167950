import React, { useState, useEffect } from 'react';
import Parallax from "react-parallax-scroll";
import { white_arrow, profile_default, help, lock } from '../../assets/images/landing';
import Usernav from '../../components/Navbar/Usernav';
import { useDispatch } from 'react-redux';
import { editAccountValidation, confirmpasswordValidation } from '../../constants';
import { Formik } from 'formik';
import InputField from '../../components/Input';
import { getUser, updateUser, profilePasswordUser } from '../../redux/actions';
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import LocationInput from '../../components/LocationInput';
import useGoogleMapsApi from "../../components/AutoCompleteGeo";


const Profile = (props) => {
    const dispatch = useDispatch();
    const [profileModalOpen, setProfileModalOpen] = useState(false);
    const [initial, setInitial] = useState({});
    const [images, setImages] = useState([]);
    const [clearimages, setClearimages] = useState([]);
    const [imageLen, setImageLen] = useState("");
    const [preview, setPreview] = useState([]);
    const [successLoader, setSuccessLoader] = useState(false);
    const [imageUrl, setImageUrl] = useState([]);
    const [show, setShow] = useState(false);
    const [showMsg, setShowMsg] = useState();
    const [imageChange, setImageChange] = useState(false);
    const [homeCityNameval, setHomecityNameval] = useState("");
    const [homeCityval, setHomecityval] = useState("");
    const [homeCityLatval, setHomecityLatval] = useState("");
    const [homeCityLongval, setHomecityLongval] = useState("");
    const [homeCityPlaceval, setHomecityPlaceval] = useState("");
    const [homeCityErr, sethomeCityErr] = useState(1);
    const [locationval, setLocationval] = useState();
    const [avatar, setAvatar] = useState();
    const googleMapsApi = useGoogleMapsApi();

    let curtime = new Date().getTime();
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const getLocation = (name,city, lat, long,placeid) => {
        setHomecityNameval(name);
        setHomecityval(city);
        setHomecityLatval(lat);
        setHomecityLongval(long);
        setHomecityPlaceval(placeid);
    };

    const checkLocation = () => {
        var addressField = document.getElementById("location_input");
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
            'address': addressField.value
        }, function (results, status) {
            if (status === googleMapsApi.GeocoderStatus.OK && results.length > 0) {
                //addressField.value = results[0].formatted_address;
            } else {
                sethomeCityErr(3);
                return false;
            }
        });
    }

    const handleSubmit = async (values) => {
        if (!homeCityval) {
            if (homeCityLatval) {
                sethomeCityErr(2);
                return false;
            }
        }
        else {
            if (homeCityErr === 2 || homeCityErr === 3) {
                setImageLen("Please choose valid address");
                return false;
            }
            else {
                setImageLen("");
                setSuccessLoader(true);
                let secondStepVal = { ...values, homeCityName: homeCityNameval, homeCity: homeCityval, homeCityLat: homeCityLatval, homeCityLon: homeCityLongval,homeCityPlaceId: homeCityPlaceval, isSubscribed: 1 };
                let result = await dispatch(updateUser({ value: secondStepVal, img: images }));
                if (result?.payload?.status || result?.payload === "succeeded") {
                    setSuccessLoader(false);
                    const accessToken = localStorage.getItem('accessToken');
                    if (accessToken) {
                        let datas = await dispatch(getUser());
                        setInitial(datas.payload.with ? datas.payload.with : datas.payload.data.with);
                        if (datas?.payload?.with?.avatar === null || datas?.payload?.with?.avatar === undefined) {
                            setAvatar(datas?.payload?.data?.with?.avatar !== null &&  datas?.payload?.data?.with?.avatar !== undefined ? datas?.payload?.data?.with?.avatar + "?" + curtime : "");
                        }
                        else {
                            setAvatar(datas?.payload?.with?.avatar ? datas?.payload?.with?.avatar + "?" + curtime : datas?.payload?.data?.with?.avatar + "?" + curtime);
                        }
                        setPreview([]);
                        setImages([]);
                        setClearimages([]);
                    }
                    setImageChange(false);
                    goToTop();
                    setShowMsg("Successfully Updated");
                    setShow(true);
                }
                else {
                    setImageLen("Profile updates are failed - Please try once again");
                    setPreview([]);
                    setImages([]);
                    setClearimages([]);
                    setSuccessLoader(false);
                }
            }
        }
    }

    const handlePassword = async (values) => {
        let result = await dispatch(profilePasswordUser(values));
        if (result?.payload?.status || result?.payload === "succeeded") {
            setSuccessLoader(false);
            setShowMsg("Password Updated Successfully");
            setShow(true);
            setProfileModalOpen(false);
        }
        else {
            setImageLen("Something went wrong, please try again later");
            setPreview([]);
            setImages([]);
            setClearimages([]);
            setSuccessLoader(false);
        }
    };


    useEffect(async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            let datas = await dispatch(getUser());
            setInitial(datas?.payload?.with ? datas?.payload?.with : datas?.payload?.data?.with);
            if (datas?.payload?.with?.avatar === null || datas?.payload?.with?.avatar === undefined) {
                setAvatar(datas?.payload?.data?.with?.avatar !== null &&  datas?.payload?.data?.with?.avatar !== undefined ? datas?.payload?.data?.with?.avatar + "?" + curtime : "");
            }
            else {
                setAvatar(datas?.payload?.with?.avatar ? datas?.payload?.with?.avatar + "?" + curtime : datas?.payload?.data?.with?.avatar + "?" + curtime);
            }
            if (datas?.payload?.with?.homeCity?.name === "[object Object]" || datas?.payload?.data?.with?.homeCity?.name === "[object Object]") {
                setLocationval("");

            }
            else {
                setLocationval(datas?.payload?.with?.homeCity?.name || datas?.payload?.data?.with?.homeCity?.name);
                setHomecityval(datas?.payload?.with?.homeCity?.name || datas?.payload?.data?.with?.homeCity?.name);
                setHomecityLatval(datas?.payload?.with?.homeCity?.lat || datas?.payload?.data?.with?.homeCity?.lat);
                setHomecityLongval(datas?.payload?.with?.homeCity?.lon || datas?.payload?.data?.with?.homeCity?.lon);
            }
        }
    }, []);


    const loadImage = () => {
        let arrayofFiles = [];
        let blobofFiles = [];
        let imageshold = clearimages;
        if (clearimages.length > 0) {
            for (var i = 0; i < imageshold?.length; i++) {
                var extension = imageshold[i]?.type;
                if (extension.includes("application")) {
                    setImageLen("Please try with upload image formats only");
                    return false;
                }
                else {
                    if (imageshold[i]?.size > 5242880) {
                        setImageLen("Upload file size is too big - Please try less than 5 mb");
                        return false;
                    }
                    else {
                        arrayofFiles.push(imageshold[i]);
                        setImageLen("");
                    }
                }
            }
            arrayofFiles.map((e) => {
                const ImageUrl = URL.createObjectURL(e);
                blobofFiles.push(ImageUrl);
            })
            setPreview(blobofFiles);
            setImages(clearimages);
        }
    }

    const imageValidate = () => {
        setImageLen("Please upload only one image");
        setSuccessLoader(false);
        return false;
    }

    const onSelectFile = (e) => {
        setImageChange(true);
        let arrayofFiles = [];
        let blobofFiles = [];
        let images_select = [];
        let totalimages = 0;
        if (e.target.files.length > 1) {
            return imageValidate();
        }
        else {
            if (e.target.files.length < 2) {
                for (var i = 0; i < e.target.files.length; i++) {
                    var extension = e.target.files[i].type;
                    if (extension.includes("application") || extension.includes("audio") || extension.includes("video")) {
                        setImageLen("Please try with upload image formats only");
                        return false;
                    }
                    else {
                        if (e.target.files[i].size > 5242880) {
                            setImageLen("Upload file size is too big - Please try less than 5 mb");
                            return false;
                        }
                        else {
                            arrayofFiles.push(e.target.files[i]);
                            if (clearimages.length >= 1) {
                                clearimages.pop();
                                clearimages.push(e.target.files[i]);
                            }
                            else {
                                clearimages.push(e.target.files[i]);
                            }
                            setImageLen("");
                        }
                    }
                }
                arrayofFiles.map((e) => {
                    const ImageUrl = URL.createObjectURL(e);
                    images_select.push(ImageUrl);
                    blobofFiles.push({ e, ImageUrl });
                })
                setImages(arrayofFiles);
                setImageUrl(blobofFiles);
                totalimages = preview.length + images_select.length;

                if (totalimages === 1) {
                    setPreview(images_select);
                }
                else {
                    setPreview(images_select);
                }
            }
            else {
                setPreview([]);
                setImages([]);
                setClearimages([]);
            }
            if (e.target.value === "") {
                loadImage();
            }
        }
    }

    const filterUrlItems = (arr, query) => {
        return arr.filter(function (el) {
            return el.ImageUrl !== query;
        })
    }

    const removeImageFromArray = (e) => {
        const index = e.target.id;
        let img_name = e.target.getAttribute("name");
        let newPreview = [...preview];
        newPreview.splice(index, 1);
        if (index === "1") {
            document.getElementById("previewimage0").style.display = "block";
        }
        setPreview(newPreview);
        if (newPreview.length === 0) {
            document.getElementById('image_upload').value = '';
            setImages([]);
            setClearimages([]);
        }
        if (newPreview.length === 1) {
            const result3 = filterUrlItems(imageUrl, img_name);
            setImages([result3[0].e]);
            setClearimages([result3[0].e]);
        }
    }



    return (
        <Parallax>
            <div className='welcome_bg'>
                <div className='campaign_cover'>
                    <header className="header-area header-area ">
                        <Usernav modalView="partial" banner_title="Profile"></Usernav>
                    </header>
                    <div className="container clscampaign_card_profile">
                        <div className="row col-lg-12 clscampaignrow">
                            <Formik enableReinitialize={true} initialValues={initial}
                                validationSchema={editAccountValidation}
                                onSubmit={handleSubmit}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    isValid
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row clsprofile_rows clsmargin_none">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 clscampaigncol clshelp_header">
                                                <div className='cls_profiebg'>
                                                    <div className='cls_profileImg'>
                                                        <input id="image_upload" type="file" name="file" accept=".gif,.jpg,.jpeg,.png" className='hideProfileClass' onChange={onSelectFile} />
                                                        {avatar && !imageChange ? <img id="clsavatar" src={avatar} alt="" className='clsavatar_profile cls1' />
                                                            : preview.length && imageChange ?
                                                                preview.map((img, index) =>
                                                                (
                                                                    <div key={index} id={"previewimage" + index} style={{ display: preview.length >= 2 && index === 1 ? "none" : "" }} data-id={preview.length + "--" + clearimages.length} className={"profile_preview profile_preview_" + preview.length}>
                                                                        <img src={img} className="images clsavatar_profile cls2" id={index} alt="pic1" />
                                                                        <button
                                                                            className='clsimg_close'
                                                                            id={index}
                                                                            key={index}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                removeImageFromArray(e);
                                                                            }}
                                                                            name={img}
                                                                        >
                                                                            x
                                                                        </button>
                                                                    </div>
                                                                )
                                                                ) :
                                                                <img src={profile_default} alt="Profile" />}
                                                        <span className=''>{avatar ? "  Edit" : "Add"} Profile Image</span>
                                                    </div>
                                                    {imageLen ? <div className='clsErrorName clsProfileError'>{imageLen}</div> : ""}
                                                </div>
                                                <div className='clsToast'>
                                                    <Toast className="clsbox_center" bg={"danger"} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                                        <Toast.Body className='success text-green'>{showMsg}</Toast.Body>
                                                    </Toast>
                                                </div>
                                                <div className='cls_help'>
                                                    <img src={help} alt="Help" />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 clscampaigncol clsprofile">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <InputField name="firstName" type="text" cssclass="clsinput clsprimary" placeholder="" label="First Name"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values?.firstName} error={errors.firstName && touched.firstName ? errors.firstName : undefined} tabindex='1' />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <InputField name="lastName" type="text" cssclass="clsinput clsprimary" placeholder="" label="Last Name"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values?.lastName} error={errors.lastName && touched.lastName ? errors.lastName : undefined} tabindex='2' />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <InputField name="phone_number" type="text" cssclass="clsinput clsprimary" placeholder="" label="Phone Number" max="11"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values?.phone_number} error={errors.phone_number && touched.phone_number ? errors.phone_number : undefined} tabindex='3' />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <InputField name="username" type="text" cssclass="clsinput clsprimary" placeholder="" label="Business Name"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values?.username} error={errors.username && touched.username ? errors.username : undefined} tabindex='4' />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <InputField name="email" type="text" cssclass="clsinput clsprimary" placeholder="" read="true" label="Business E-mail"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values?.email} error={errors.email && touched.email ? errors.email : undefined} tabindex='5' />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <LocationInput name="homeCity" id="location_input" parentCallback={getLocation} edit="true" val={locationval} label="Address" multiple="false"
                                                            onChange={e => { setFieldValue('homeCity', homeCityval); sethomeCityErr(1) }}
                                                            onBlur={e => { setFieldValue('homeCity', homeCityval); sethomeCityErr(1); checkLocation(); }} tabindex='6' />
                                                        {homeCityErr === 2 ? <div className="error_input error_prof_address"> Required </div> : ""}
                                                        {homeCityErr === 3 ? <div className="error_input error_address">Please choose valid address</div> : ""}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <InputField name="website" type="text" cssclass="clsinput clsprimary" placeholder="" label="Website URL"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values?.website} error={errors.website && touched.website ? errors.website : undefined} tabindex='7' />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <InputField name="Plan" type="text" cssclass="clsinput clsprimary" placeholder="" label="Payment Plan" read="true"
                                                            onBlur={handleBlur} onChange={handleChange}
                                                            value={values?.payment_plan !== "" && values?.payment_plan !== "null" && values?.payment_plan !== "undefined" ? values?.payment_plan : "Free"} error={errors.payment_plan && touched.payment_plan ? errors.payment_plan : undefined} tabindex='8' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 clshelp_header">
                                            {successLoader ? <div className='campaign-performance-loader'></div> : ""}
                                            <div className='clsreset_img'>
                                                <img src={lock} alt="" />
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    setProfileModalOpen(true);
                                                }}>Reset Password</button>
                                            </div>
                                            <div className='clscampaignlink clscampaignmargin clsmargin_none'>
                                                <button type='submit'>Save <img src={white_arrow} alt="White Arrow" /></button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <Modal
                        dialogClassName='delete-modal-content modal-dialog modal-dialog-centered contact_modal_inn cls_change_pwd_modal'
                        centered
                        show={profileModalOpen}
                        animation={true}
                        onHide={() => { setProfileModalOpen(false); }}
                        id="delete_modal"
                        backdrop="static"
                        keyboard={false}
                    >
                        <div className='contact-modal-contain'>
                            <div className='modal-header'>
                            </div>
                            <div className='modal-body'>
                                <Formik
                                    initialValues={{ password: '', confirm_password: '' }}
                                    validationSchema={confirmpasswordValidation}
                                    onSubmit={(values, { resetForm }) => {
                                        handlePassword(values);
                                        resetForm({ values: "" });
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isValid
                                    }) => (
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            handlePassword(values);
                                        }}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <InputField name="password" type="password" cssclass="clsinput clsinput_reset" placeholder="" label="Password"
                                                        onBlur={handleBlur} onChange={handleChange}
                                                        value={values.password} error={errors.password && touched.password ? errors.password : undefined} eye_img="true" eye_class="true" tabindex="1" focusId="passw" />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <InputField name="confirm_password" type="password" cssclass="clsinput clsinput_reset" placeholder="" label="Confirm Password"
                                                        onBlur={handleBlur} onChange={handleChange}
                                                        value={values.confirm_password} error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : undefined} eye_img="true" eye_class="true" tabindex="2" focusId="cpassw" />
                                                </div>
                                            </div>
                                            <div className='row clscampaignlink clscampaignmargin clsmargin_none clspassword_btn'>
                                                <button type="submit" className='clsreset_btn'>Save</button>
                                                <button type="button" onClick={(e) => { e.preventDefault(); setProfileModalOpen(false); }} className='clsreset_btn'>Cancel</button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className='clsCircleBg'></div>
            </div>
        </Parallax>
    )
}

export default Profile;