import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Parallax from "react-parallax-scroll";
import {introductionImage,learnmoreImage,spans1,spans2,spans3,registerImage,homeImage} from '../assets/images/landing';
import Homenav from '../components/Navbar/Homenav';
import Button from '../components/Button';
const Landing = () => {
  const history = useHistory();
  const [contact_modal_open, setContactModalOpen] = useState(false);
  const NavigateToPayment = event => history.push('/payment');
  const NavigateToLearn = event => history.push('/learn-more');
  const getVideoStatus = (data) => {
    setContactModalOpen(data);
  }
  return (
    <Parallax>
      <div className='landing_bg'>
        <header class="header-area header-area ">
          <Homenav modalStatus={contact_modal_open} parentCallback={getVideoStatus}></Homenav>
          <div class="header-content-area header-content-area ">
            <div class="container">
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                  <div className='clsbigfont'> Your Message <br /><span className="clsbigfont_withyou">At Their</span><br /><span className='clssmallfont_withyou'>Fingertips</span></div>
                  <div className='clsspring1img'><img src={spans1} alt="Spring" /> </div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                  <div className='clshomeimg'><img src={homeImage} alt="HomeImage" /></div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-content-area header-content-area ">
            <div class="container">
              <div class="row clssavebar">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className='clsCircleBg_Landing'></div> 
                  <div className='clsspring3img'><img src={spans3} alt="Spring3"/> </div> 
                  <div className='clsspring2img'><img src={spans2} alt="WithYou" /> </div> 
                </div>
              </div>
            </div>
          </div>
           <div className='clsMapbg'></div>
        </header>
        <section class="partners-area partners desk_view">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div className='clsbox'>
                <div className='clsboxhead clsLearnMore'><img src={learnmoreImage} alt="LearnMore" /> <span>Learn More</span></div>
                <div className='clsboxcontent clsLearnMore_cnt'>Explore how WithYou works for your business.</div>
                <div className='clsboxlink'>
                  <Button text="Learn More" onClick={NavigateToLearn}></Button>
                </div>
                </div>
              </div>
              <div class="col-lg-4">
              <div className='clsbox'>
              <div className='clsboxhead'><img src={introductionImage} alt="Introduction" /> <span>Introduction Video</span></div>
                <div className='clsboxcontent'>See WithYou in action.
                </div>
                <div className='clsboxlink'>
                  <Button text="Watch Now" onClick={(e) => { e.preventDefault(); setContactModalOpen(true) }}></Button>
                </div>
                </div>
              </div>
              <div class="col-lg-4">
              <div className='clsbox'>
              <div className='clsboxhead'><img src={registerImage} alt="registerImage" /> <span>Get Started</span></div>
                <div className='clsboxcontent'>Get started for free in less than 5 minutes!</div>
                <div className='clsboxlink'>
                  <Button text="Get Started" onClick={NavigateToPayment}></Button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Parallax>
  )
}

export default Landing;