import React,{useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import Parallax from "react-parallax-scroll";
import { free_icon, annual_icon, monthly_icon, white_arrow } from '../assets/images/landing';
import Homenav from '../components/Navbar/Homenav';
import { useDispatch } from 'react-redux';
import { verifyMail } from '../redux/actions';

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userid = params.get('email');
  const [verifyState, setVerifyState] = useState();
  useEffect(async() => {
    let result = await dispatch(verifyMail(userid));
    setVerifyState(result.payload);
  }, []);
  const NavigateToRegister = (value) => {
    if(verifyState === 400)
    {
      history.push('/register', { plan: value, id: userid });
    }
    else{
      history.push('/register', { plan: value });
    }
  };
  return (
    <Parallax>
      <div className='welcome_bg payment_bg'>
        <header className="header-area header-area ">
          <Homenav modalView="partialheader"></Homenav>
          <div className="header-content-area header-content-area ">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <div className='clsbigfont3'> Welcome to <span className="clsbigfont_withyou">WithYou</span></div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-content-area header-content-area ">
            <div className="container">
              <div className="row clssavebar2">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className='clssmallfont'> Please select your plan</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row col-lg-12">
              <div className="col-lg-4 clsboxcover">
                <div className='clsbox2'>
                  <div className='clsboximg'><img src={free_icon} alt="Free Plan" /></div>
                  <div className='clsboxhead2'>
                    Single Location
                  </div>
                  <div className='clsboxlist'>
                    <ul>
                      <li>Unlimited monthly campaigns</li>
                      <li>No long term contracts</li>
                      <li>Instant campaign reporting and analytics</li>
                    </ul>
                  </div>
                  <div className='clsboxlink buttonsecond'>
                    <button type='button' onClick={(e) => { e.preventDefault(); NavigateToRegister("Free") }}>Select<img src={white_arrow} alt="Arrow" /></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 clsboxcover">
                <div className='clsbox2'>
                  <div className='clsboximg'><img src={monthly_icon} alt="Monthly Plan" /></div>
                  <div className='clsboxhead2'>
                    2 to 5 Locations
                  </div>
                  <div className='clsboxlist'>
                    <ul>
                      <li>Unlimited monthly campaigns</li>
                      <li>No long term contracts</li>
                      <li>Instant campaign reporting and analytics</li>
                    </ul>
                  </div>
                  <div className='clsboxlink buttonsecond'>
                    <button type='button' onClick={(e) => { e.preventDefault(); NavigateToRegister("Monthly") }}>Select<img src={white_arrow} alt="Arrow" /></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 clsboxcover">
                <div className='clsbox2'>
                  <div className='clsboximg'><img src={annual_icon} alt="Annual Plan" /></div>
                  <div className='clsboxhead2'>
                    6 or More Locations
                  </div>
                  <div className='clsboxlist'>
                    <ul>
                      <li>Unlimited monthly campaigns</li>
                      <li>No long term contracts</li>
                      <li>Instant campaign reporting and analytics</li>

                    </ul>
                  </div>
                  <div className='clsboxlink buttonsecond'>
                    <button type='button' onClick={(e) => { e.preventDefault(); NavigateToRegister("Annual") }}>Select<img src={white_arrow} alt="Arrow" /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='clsCircleBg'></div>
        </header>
      </div>
    </Parallax>
  )
}

export default Payment;