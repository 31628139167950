import React,{useEffect,useState} from 'react';
import { useHistory,useParams } from "react-router-dom";
import Parallax from "react-parallax-scroll";
import { reset_home, white_arrow } from '../assets/images/landing';
import Homenav from '../components/Navbar/Homenav';
import InputField from '../components/Input';
import { useDispatch } from 'react-redux';
import { resetVerifyUser,confirmPasswordUser } from '../redux/actions';
import { Formik } from 'formik';
import { confirmpasswordValidation } from '../constants';
const ConfirmPassword = () => {
    const  token  = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [tokenval,Settokenval] = useState("");
    const [successMessage,Setsuccessmessage] = useState("");
    const [expiryMessage,Setexpirymessage] = useState("Exact Link");
    const NavigateToRegister = event => history.push('/register');
    const handleSubmit = async(values) => {
        let confirm_password = values.confirm_password;
        let token = tokenval;
        let password = values.password;
        let result =  await dispatch(confirmPasswordUser({password,confirm_password,token}));
        Setsuccessmessage(result?.payload);
        if(result?.payload === "succeeded")
        {
            setTimeout(() => {
                history.push('/login');
              }, 3000);
        }
    };
    useEffect(() => {
        async function fetchData() {
        var verify_token = token;
        let result =  await dispatch(resetVerifyUser({verify_token}));
        if(result?.payload === "Expired Link")
        {
            Setexpirymessage("Expired Link");
        }
        else{
            Settokenval(result?.payload);
            Setexpirymessage("Exact Link");
        }
        }
        fetchData();
    }, []);
    return (
        <Parallax>
            <div className='register_bg'>
                <div className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 leftbox_bg">
                        <header className="header-area header-area ">
                            <Homenav modalView="partial"></Homenav>
                            <div className="header-content-area header-content-area ">
                                <div className="container">
                                    <div className="row register_row">
                                        <Formik
                                            initialValues={{ password: '', confirm_password:'' }}
                                            validationSchema={confirmpasswordValidation}
                                            onSubmit={(values, { resetForm }) => {
                                                handleSubmit(values);
                                                resetForm({ values: "" });
                                              }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isValid
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className='clsbigfont4 clsfont'>Create New Password</div>
                                                    <div className='clsSaved'>{successMessage === "succeeded" ? "Saved Successfully" : ""}</div>
                                                    <div className='clsError'>{expiryMessage === "Expired Link" ? "The Reset password link got expired! - Please try new" : ""}</div>
                                                    {expiryMessage === "Exact Link" ? (
                                                    <div className='clsreset'>
                                                     <div className="row clsconfirm_rows">
                                                        <div className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>
                                                            <div className='clsconfirm_content'>{successMessage === "succeeded" ? "" : "Your new password must be different from previous used passwords." }</div>
                                                        </div>
                                                    </div>
                                                    <div className="row clsconfirm_rows">
                                                        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                                            <InputField name="password" type="password" cssclass="clsinput clsinput_reset" placeholder="" label="Password"
                                                                onBlur={handleBlur} onChange={handleChange}
                                                                value={values.password} error={errors.password && touched.password ? errors.password : undefined} eye_img="true" eye_class="true" />
                                                            <InputField name="confirm_password" type="password" cssclass="clsinput clsinput_reset" placeholder="" label="Confirm Password"
                                                                onBlur={handleBlur} onChange={handleChange}
                                                                value={values.confirm_password} error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : undefined} eye_img="true" eye_class="true" />
                                                        </div>
                                                    </div>
                                                    <div className="row clsbtn_rowreset">
                                                        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                                            <button type='submit' className='confirm_btn'>Reset Password <img src={white_arrow} alt="White Arrow" /></button>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    ) :""}
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </header>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 rightbox_bg">
                        <img src={reset_home} className="reset_home" alt="Logo" />
                    </div>
                </div>
            </div>
        </Parallax>
    )
}
export default ConfirmPassword;