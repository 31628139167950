import axios from 'axios';

const responseInterceptor = () => {
  axios.interceptors.response.use((response) => {
    const { data } = response;
    return data;
  }, (error) => {
    return Promise.reject(error);
  });
};

const requestInterceptor = () => {
  axios.interceptors.request.use((config) => {
    // Do something before request is sent
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  }, (error) => {
    // Do something with request error
    return Promise.reject(error);
  });
};

const getData = async (url, params) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return await axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
        params,
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
    }
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.setItem("accessToken", "");
      localStorage.setItem("userAddress", "");
      window.location = '/login';
    }
    return error.response.data;
  }
};

const postData = async (url, params, options) => {
  try {
    return await axios.post(`${process.env.REACT_APP_API_URL}${url}`, params);
  } catch (error) {
    //  throw new Error(error);
    return error.response.data;
  }
};

const postDataForm = async (url, params, options) => {
  const formData = new FormData();
  formData.append('title', params.title);
  for (let i = 0; i < params.file.length; i++) {
    formData.append('file', params.file[i], 'image'+i);
  }
  formData.append('startDate', params.startDate);
  formData.append('endDate', params.endDate);
  formData.append('placeLon', params.placeLon);
  formData.append('placeLat', params.placeLat);
  formData.append('placeName', params.placeName);
  formData.append('placeAddress', params.placeAddress);
  formData.append('category', params.category);
  formData.append('keywords', params.keywords);
  formData.append('placeIds',params.placeIds);
  const config = {
    headers: {
      'content-type': 'multipart/form-data; boundary=acddfdfdf'
    }
  }
  try {
    return await axios.post(`${process.env.REACT_APP_API_URL}${url}`, formData, config);
  } catch (error) {
    //  throw new Error(error);
    return error.response.data.because;
  }
};


const updateDataForm = async (url, params, options) => {
  const formData = new FormData();
  formData.append('title', params.title);
  if (params.updated === true) {
    for (let i = 0; i < params.file.length; i++) {
      formData.append('file', params.file[i], 'image'+i);
    }
  }
  formData.append('startDate', params.startDate);
  formData.append('endDate', params.endDate);
  if (params.removeImages.length > 0) {
  if (params.removeImages.length < 2) {
    var arr0 = params.removeImages[0];
    formData.append('removeImages', JSON.stringify(arr0));
  }
  if (params.removeImages.length >= 2) {
    var arr1 = params.removeImages[0];
    var arr2 = params.removeImages[1];
    var arr3 = [...arr1, ...arr2];
    formData.append('removeImages', JSON.stringify(arr3));
  }
}
  formData.append('category', params.category);
  formData.append('keywords', params.keywords);
  formData.append('placeIds',params.placeIds);
  const config = {
    headers: {
      'content-type': 'multipart/form-data; boundary=acddfdfdf'
    }
  }
  try {
   return await axios.post(`${process.env.REACT_APP_API_URL}${url}`, formData, config);
  } catch (error) {
    return error.response.data.because;
  }
};



const postUserForm = async (url, params, options) => {
  const formData = new FormData();
  formData.append('email', params.value.email);
  if(params.img.length > 0)
  {
    for (let i = 0; i < params.img.length; i++) {
      formData.append('file', params.img[i], 'image'+i);
    }
  }
  formData.append('firstName', params.value.firstName);
  formData.append('lastName', params.value.lastName);
  formData.append('homeCityName',params.value.homeCityName);
  formData.append('homeCity', params.value.homeCity);
  formData.append('homeCityLat', params.value.homeCityLat);
  formData.append('homeCityLon', params.value.homeCityLon);
  formData.append('homeCityPlaceId',params.value.homeCityPlaceId);
  formData.append('username', params.value.username);
  formData.append('website', params.value.website);
  formData.append('phone_number', params.value.phone_number);
  formData.append('reference', params.value.reference);
  formData.append('payment_plan', params.value.payment_plan);
  formData.append('payment_method', params.value.payment_method);
  const config = {
    headers: {
      'content-type': 'multipart/form-data; boundary=acddfdfdf'
    }
  }
  try {
    return await axios.post(`${process.env.REACT_APP_API_URL}${url}`, formData, config);
  } catch (error) {
    //  throw new Error(error);
    return error.response.data.because;
  }
};

const putData = async (url, params, options) => {
  try {
    return await axios.put(`${process.env.REACT_APP_API_URL}${url}`, { params }, options);
  } catch (error) {
    throw new Error(error);
  }
};

const deleteData = async (url, params) => {
  try {
    return await axios.delete(`${process.env.REACT_APP_API_URL}${url}${params}`);
  } catch (error) {
    throw new Error(error);
  }
};

export const ApiHelpers = {
  responseInterceptor,
  requestInterceptor,
  getData,
  putData,
  deleteData,
  postData,
  postDataForm,
  updateDataForm,
  postUserForm
};
