import React from "react";

const Select = ({ status, select_img, name, label, labelview,cssclass,cssform, options,error, idKey,tabindex, nameKey, ...rest }) => {
  return (
    <div className={cssform ? cssform + " form-group" : "form-group"}>
     {select_img ? <img src={select_img} className="clsstatus" alt="" /> : ""}
      <label htmlFor={name}>{label ? label :""}</label>
      <select name={name} id={name} {...rest} tabIndex={tabindex} value={status} className={cssclass + " form-control"}>
        <option value="">Select {labelview}</option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="error_input">{error}</div>}
    </div>
  );
};

export default Select;