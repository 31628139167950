import React, { useState, useEffect } from 'react';
import Parallax from "react-parallax-scroll";
import { useHistory } from "react-router-dom";
import { duplicate_icon, delete_icon, chart_icon } from '../../assets/images/landing';
import Usernav from '../../components/Navbar/Usernav';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { getManageCampaign, deleteCampaign, duplicateCampaign } from '../../redux/actions';
import Search from '../../components/Search';
import Toast from 'react-bootstrap/Toast';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
const Performance = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sliderData, setSliderData] = useState([]);
    const [sliderLoad, setSliderLoad] = useState(false);
    const [show, setShow] = useState(false);
    const [showMsg, setShowMsg] = useState("Successfully Completed !");
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [comingModalOpen, setComingModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [errorMsgLoad, setErrorMsgLoad] = useState(false);
    const delete_Campaign = async (offerId) => {
        let delete_result = await dispatch(deleteCampaign(offerId));
        if (delete_result?.payload?.status === "succeeded") {
            setDeleteModalOpen(false);
            setShowMsg("Deleted Successfully");
            setShow(true);
            setDeleteId("");
            document.getElementById("search_input").value = "";
            let datas = await dispatch(getManageCampaign());
            getCampaign_datas();

        }
    }

    const delete_Modal = (offerId) => {
        setDeleteModalOpen(true);
        setDeleteId(offerId);
    }

    const duplicate_Campaign = async (offerId) => {
        let duplicate_result = await dispatch(duplicateCampaign(offerId));
        if (duplicate_result?.payload?.status === "succeeded") {
            setShowMsg("Copy Created Successfully");
            setShow(true);
            history.push('/campaign/' + duplicate_result?.payload?.with);
        }
    }

    const getCampaign_datas = async () => {
        setErrorMsgLoad(false);
        setSliderData([]);
        let datas = await dispatch(getManageCampaign());
        setSliderData(datas?.payload?.with);
        setTimeout(() => {
            setErrorMsgLoad(true);
        }, 1000);
    }

    const handleCampaign_datas = async (e) => {
        setErrorMsgLoad(false);
        setSliderData([]);
        let datas = await dispatch(getManageCampaign({ keyword: e.target.value }));
        setSliderData(datas?.payload?.with);
        setTimeout(() => {
            setErrorMsgLoad(true);
        }, 1000);
        customScrollbar();
    }

    const customScrollbar = () => {
        var container = $('#scrollcontainer'),
            content = $('#content'),
            scroll = $('#scrollbar'),
            doc = $(document);
        content.on('scroll', function (e) {
            scroll.stop(true).css({
                opacity: 1,
                height: Math.pow(container.height(), 2) / content[0].scrollHeight,
                top: content.height() * content[0].scrollTop / content[0].scrollHeight + 5
            });
        });
        window.addEventListener('resize', content.trigger.bind(content, 'scroll'));
        content.trigger('scroll');
        scroll.on('mousedown', function (e) {
            e.preventDefault();
            var y = scroll[0].offsetTop;
            var y1 = e.originalEvent.pageY;
            doc.on('mousemove', function (e) {
                var y2 = e.originalEvent.pageY;
                scroll.css('top', Math.min(container.height() - scroll.height() + 5, Math.max(5, y + y2 - y1)));
                content[0].scrollTop = (content[0].scrollHeight * scroll[0].offsetTop / content.height());

            });
            doc.on('mouseup', function () {
                doc.off('mousemove');
            });
        });
    }

    useEffect(async () => {
        setSliderLoad(true);
        await getCampaign_datas();
        if (sliderData.length === 0) {
            await getCampaign_datas();
        }
        // Custom Scrollbar
        customScrollbar();
    }, [!sliderLoad]);
    return (
        <Parallax>
            <div className='welcome_bg'>
                <div className='campaign_cover'>
                    <header className="header-area header-area ">
                        <Usernav modalView="partial" banner_title="Performance"></Usernav>
                    </header>
                    <div className={sliderData?.length > 10 ? "container clsperformance_card_max" : sliderData?.length > 5 ? "container clsperformance_card":"container clsperformance_card_small"}>
                        <div className="row col-lg-12 clscampaignrow">
                            <Toast className="clsbox_right" bg={"danger"} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                <Toast.Body className='success text-green'>{showMsg}</Toast.Body>
                            </Toast>
                            <div className='clsperformance_search'>
                                <Search type="text" search="true" id="search_input" cssclass={deleteId ? "clssearchinput2":"clssearchinput"} placeholder="" name="searchName"
                                    onChange={handleCampaign_datas} label="Search Campaign" tabindex='1'></Search>
                            </div>
                            <div class="perform_head_container">
                                <div className='cls_thumb_img_head'></div>
                                <div className='cls_thumb_name_head'>
                                <div>Campaign Name</div>
                                <div>Start Date</div>
                                <div>End Date</div>
                                <div>Total View</div>
                                <div>Total Saves</div>
                                <div>Total Redeems</div>
                                <div>Duplicate</div>
                                <div>Delete</div>
                                <div>Full Report</div>
                                </div>
                            </div>
                            <div class="perform_cover_container">
                                {sliderData?.length > 0 ? (
                                    <div id="scrollcontainer" className={sliderData?.length > 10 ? "clsscrollrow_max" : sliderData?.length > 5 ? "clsscrollrow_min" : "clsscrollrow_small"}>
                                        {sliderData?.length > 5 ? <div id="scrollbar"></div> :""}
                                        <div id="content">
                                            <div className={sliderData?.length > 10 ? "row clsmanage_rows clsperformance_rows_max" : sliderData?.length > 5 ? "row clsmanage_rows clsperformance_rows": "row clsmanage_rows clsperformance_rows_small"}>
                                                {sliderData?.map((item, index) => (
                                                    <div className={index % 2 === 0 ? "clsodd_row perform_content_container" : "clseven_row perform_content_container"}>
                                                        <div className='cls_thumb_img'><img src={item?.images[0]?.url} alt="" /></div>
                                                        <div className='cls_thumb_name'><div>{item.title}</div> <div>{moment(item.startDate).format('L')}</div> <div>{moment(item.endDate).format('L')}</div>
                                                            <div>{item.views}</div> <div>{item.saves}</div><div>{item.uses}</div> <div><button type='button' onClick={(e) => { e.preventDefault(); duplicate_Campaign(item.id) }}><img src={duplicate_icon} alt="" /></button></div>
                                                            <div><button type='button' onClick={(e) => { e.preventDefault(); delete_Modal(item.id) }}><img src={delete_icon} alt="" /></button></div>
                                                            <div><button type='button' onClick={(e) => { e.preventDefault(); setComingModalOpen(true); }}><img src={chart_icon} alt="" /></button></div></div></div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : errorMsgLoad === false ? <div className='campaign-performance-loader'></div> : ""}
                            </div>
                            {errorMsgLoad === true && sliderData?.length === 0 ? <div className='cls_norecords'><span>You have no offers</span></div> : ""}
                        </div>
                    </div>
                    <Modal
                        dialogClassName='delete-modal-content modal-dialog modal-dialog-centered contact_modal_inn'
                        centered
                        show={deleteModalOpen}
                        animation={true}
                        onHide={() => { setDeleteModalOpen(false);setDeleteId(""); }}
                        id="delete_modal"
                    >
                        <div className='contact-modal-contain'>
                            <div className='modal-header'>
                                <div className="section-title mt-35">
                                    Are you sure you want to delete this, click yes
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {setDeleteModalOpen(false);setDeleteId("")}}><span aria-hidden="true">x</span></button>
                            </div>
                            <div className='modal-body'>
                                <div className='row delete_btn_cancel'>
                                    <button type="button" className='clsyes_btn' onClick={(e) => { e.preventDefault(); delete_Campaign(deleteId) }}><span aria-hidden="true">Yes</span></button>
                                    <button type="button" data-dismiss="modal" aria-label="Close" onClick={() =>{setDeleteId("");setDeleteModalOpen(false)}}><span aria-hidden="true">No</span></button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        dialogClassName='delete-modal-content modal-dialog modal-dialog-centered contact_modal_inn'
                        centered
                        show={comingModalOpen}
                        animation={true}
                        onHide={() => { setComingModalOpen(false); }}
                        id="delete_modal"
                    >
                        <div className='contact-modal-contain'>
                            <div className='modal-header'>
                                <div className="section-title mt-35">
                                    
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {setComingModalOpen(false);}}><span aria-hidden="true">x</span></button>
                            </div>
                            <div className='modal-body'>
                                <div className='row delete_btn_cancel comingModal'>
                                    <span>Coming Soon</span>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className='clsCircleBg'></div>
            </div>
        </Parallax>
    )
}
export default Performance;