import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiHelpers } from '../../../helpers';

export const createCampaign = createAsyncThunk('campaign/create',async (credentials, { dispatch }) => {
    try {
    const accessToken = await ApiHelpers.postDataForm('/v1/business/offers', credentials);
     return accessToken;
    } catch (e) {
      console.log('Campaign error: ', e);
    }
  }
);


export const getCampaignDetails = createAsyncThunk('campaign/get-campaign-details', async (id, { rejectWithValue }) => {
  try {
    let result = await ApiHelpers.getData(`/v1/offers/`+id);
    return result;
  } catch (e) {
    console.log('Campaign Details error: ', e);
  }
});



export const getCampaign = createAsyncThunk('campaign/get-campaign', async (id, { rejectWithValue }) => {
  try {
    return await ApiHelpers.getData(`/v1/offers/me`);
  } catch (e) {
    console.log('Campaign error: ', e);
  }
});


export const getManageCampaign = createAsyncThunk('campaign/get-manage-campaign', async (status, { rejectWithValue }) => {
  try {
    let datas = await ApiHelpers.getData(`/v1/business/offers`,status);
    return datas;
  } catch (e) {
    console.log('Campaign error: ', e);
  }
});


export const updateCampaign = createAsyncThunk('campaign/update-campaign', async (FormDetails, { rejectWithValue }) => {
  try {
    let datas = await ApiHelpers.updateDataForm(`/v1/offers/`+FormDetails.id+`/update`,FormDetails.values);
    return datas;
  } catch (e) {
    console.log('Update Campaign error: ', e);
  }
});


export const deleteCampaign = createAsyncThunk('campaign/delete-campaign', async (Id, { rejectWithValue }) => {
  try {
    let datas = await ApiHelpers.deleteData(`/v1/offers/`,Id);
    return datas;
  } catch (e) {
    console.log('Delete Campaign error: ', e);
  }
});

export const duplicateCampaign = createAsyncThunk('campaign/duplicate-campaign',async (offerId, { dispatch }) => {
  try {
  const campaignData = await ApiHelpers.postData('/v1/business/offers/'+offerId+'/duplicate');
   return campaignData;
  } catch (e) {
    console.log('Duplicate Campaign error: ', e);
  }
}
);

export const endCampaign = createAsyncThunk('campaign/end-campaign',async (offerId, { dispatch }) => {
  try {
  const campaignData = await ApiHelpers.postData('/v1/business/offers/'+offerId+'/end');
   return campaignData;
  } catch (e) {
    console.log('End Campaign error: ', e);
  }
}
);

// Create Place

export const createPlace = createAsyncThunk('campaign/create-place',async (places, { dispatch }) => {
  try {
  const placeResult = await ApiHelpers.postData('/v1/business/places',places);
   return placeResult;
  } catch (e) {
    console.log('Create Place error: ', e);
  }
}
);

// Delete Place
export const deletePlace = createAsyncThunk('campaign/delete-place', async (Id, { rejectWithValue }) => {
  try {
    let datas = await ApiHelpers.deleteData(`/v1/business/places/`,Id);
    return datas;
  } catch (e) {
    console.log('Delete Places error: ', e);
  }
});


export const getPlaces = createAsyncThunk('campaign/get-place', async (id, { rejectWithValue }) => {
  try {
    return await ApiHelpers.getData(`/v1/business/places`);
  } catch (e) {
    console.log('Campaign error: ', e);
  }
});
