import Userheader from "../components/Navbar/Usernav";
import Userfooter from "../components/Navbar/Userfooter";
import Homefooter from "../components/Navbar/Homefooter";
export const HomeLayout = props => {
    const {children} = props;

    return (
        <>
        {children}
        <footer id="footer" class="footer-area footer">
        <Homefooter />
        </footer>
        </>
    );
}

export const WelcomeLayout = props => {
    const {children} = props;

    return (
        <>
        {children}
        <footer id="footer" class="footer footerbg">
        <Homefooter />
        </footer>
        </>
    );
}
export const SignupLayout = props => {
    const {children} = props;

    return (
        <>
        <header class="header-area classes-header-area">
        <Userheader props="partialheader" />
        </header>
            {children}
        <footer id="footer" class="footer-area footer">
        <Userfooter props="partialheader" />
        </footer>
        </>
    );
}
export const  UserLayout= props => {
    const {children} = props;

    return (
        <>
        <header class="header-area classes-header-area">
          <Userheader />
        </header>
            {children}
            <footer id="footer" class="footer-area footer user_footer user_category">
          <Userfooter />
        </footer>
        </>
    );
}