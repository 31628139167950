/**
 *
 * Button
 *
 */

 import React from 'react';
 import { white_arrow } from '../../assets/images/landing';
 const variants = {
   primary: 'custom-btn-primary',
   secondary: 'custom-btn-secondary',
   danger: 'custom-btn-danger',
   link: 'custom-btn-link',
   dark: 'custom-btn-dark',
   none: 'custom-btn-none',
   empty: ''
 };
 
 const Button = props => {
   const {
     id,
     size,
     variant,
     tabIndex,
     ariaLabel,
     ariaExpanded,
     type,
     disabled,
     className,
     text,
     role,
     icon,
     iconDirection,
     borderless,
     onClick
   } = props;
 
   const v = variant ? variants[variant] : '';
 
   // const btnVariant = icon && text ? v : icon && !text ? '' : v;
   const btnVariant = v;
 
   const btn =
     icon && text ? 'with-icon' : icon && !text ? 'icon-only' : 'text-only';
 
   const classNames = `input-btn${`${className && ` ${className}`}`}${
     btnVariant && ` ${btnVariant}`
   }${` ${size}`} ${btn} ${
     iconDirection === 'left' ? 'icon-left' : 'icon-right'
   } ${borderless ? 'border-0' : ''}`;
 
   return (
     <button
       id={id}
       tabIndex={tabIndex}
       aria-label={ariaLabel}
       aria-expanded={ariaExpanded}
       role={role}
       disabled={disabled}
       className={classNames}
       type={type}
       onClick={onClick}
     >
       {text && <span className='btn-text'>{text}</span>} {<img src={white_arrow} alt="" />}
     </button>
   );
 };
 
 Button.defaultProps = {
   type: 'button',
   variant: 'secondary',
   size: 'md',
   className: '',
   iconDirection: 'left',
   iconClassName: '',
   borderless: false
 };
 
 export default Button; 