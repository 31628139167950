import { createReducer } from '@reduxjs/toolkit';
import { getCampaign } from '../../actions';

const initialState = {
  data: null,
  loading: true,
  error: false
};

export const campaignReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCampaign.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCampaign.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(getCampaign.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    })
});
