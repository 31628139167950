import React,{useState} from 'react';
import { useHistory } from "react-router-dom";
import Parallax from "react-parallax-scroll";
import {expand,collapse } from '../assets/images/landing';
import Homenav from '../components/Navbar/Homenav';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const LearnMore = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState();
  const NavigateToRegister = (value) => {
    history.push('/register', { plan: value });
  };

  const options = [
    {
      id: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce interdum volutpat',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
    },
    {
      id: 'Bus Services',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
    },
    {
      id: 'Health & Wellness',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
    },
    {
      id: 'Proin posuere, leo in vestibulum pretium, odio tellus aliquet ligula',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
    },
    {
      id: 'Proin posuere, leo in vestibulum pretium, odio tellus aliquet ligula',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
    },
    {
      id: 'Retail',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
    },
    {
      id: 'Other',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
    },
  ];

  return (
    <Parallax>
      <div className='welcome_bg payment_bg'>
        <header className="header-area header-area ">
          <Homenav modalView="partial_learn"></Homenav>
          <div className="header-content-area header-content-area ">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <div className='clsbigfont3'> Learn More </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container clscampaign_card_learnmore">
            <div className="row col-lg-12">
              {/* <Accordion>
                {options.map((option, index) => (
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={index} onClick={(e)=>setActiveKey(index)}>
                    {index === activeKey ? <img src={collapse} /> : <img src={expand} /> } {option.id}
                    </Accordion.Toggle>
                    <Accordion.Collapse className={activeKey === 0 && index === activeKey ? "show":""} eventKey={index}>
                      <Card.Body>{option.content}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion> */}
              <p>We believe that communication between your business and consumers in your area is the #1 most important thing that drives traffic to your business.
</p>
<p>We started WithYou in 2020 as a way for local businesses to reach their customers more consistently and effectively. Our platform allows you to directly communicate about any of your new products, services, offers, promotions, events, or general news right to the fingertips of consumers on our WithYou mobile app.
</p>
<p>From there, you are able to track all the analytics from your offers and see which are doing the best, or what ones could use some work. We offer a wide variety of resources to learn best practices, and will even help craft your personal plan to help drive traffic and boost sales! Click here to see a few frequently asked questions.
</p>
<p>Ready to try WithYou? Click Book a demo  or Register on top of the page to get started completely for free. Or, book an introduction call and we would be happy to walk you through WithYou and show you how we can help.
</p>
            </div>
          </div>
          <div className='clsCircleBg'></div>
        </header>
      </div>
    </Parallax>
  )
}

export default LearnMore;