import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import { withyouImage, user_account, user_profile, user_logout, user_default_menu } from '../../assets/images/landing';
const UserNav = (props) => {
  const [contact_modal_open, setContactModalOpen] = useState(false);
  const [modalView, setModalView] = useState("");
  const [banner_title, Settitle] = useState("");
  const [profile, SetProfile] = useState(false);
  const [menutoggle, SetMenutoggle] = useState(false);
  const history = useHistory();
  const NavigateToPayment = event => history.push('/payment');
  const handleLogout = () => {
    SetProfile(true);
  }
  const handleLogout_clear = () => {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("userAddress", "");
    history.push('/login');
  }
  const navigateManage_Campaign = () => {
    history.push('/manage-campaign');
  }
  const navigateProfile = () => {
    history.push('/profile');
  }

  useEffect(() => {
    setModalView(props.modalView);
    Settitle(props.banner_title);
    if (props.modalStatus)
      setContactModalOpen(true);
  }, [props.modalStatus || props.modalOTPStatus])
  return (
    <div className="header-nav">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="navigation">
              <nav className={modalView === "partial" ? "navbar navbar-expand-lg navbar-light" : "navheader  navbar-expand-lg navbar-light"}> <a onClick={(e) => { e.preventDefault(); history.push('/homeview') }} className="navbar-brand" href="#"> <img src={withyouImage} alt="Logo" /> </a>
                <span className={modalView === "partial" ? "clsbanner_title" : "clsbanner_title2"}>{banner_title}</span> {modalView === "partial" ? <button className={(menutoggle === true ? 'cls_active_menu clsbuttonnone clsmenubutton' : 'clsbuttonnone clsmenubutton')} onClick={(e) => { e.preventDefault(); handleLogout(); SetMenutoggle(!menutoggle); }}><img src={user_default_menu} alt="Logo" /></button> : ""}
                {profile && menutoggle ? (
                  <div className='clsprofile_view'>
                    <button className='clsprofileLogout' onClick={(e) => { e.preventDefault(); navigateProfile(); }} ><img src={user_profile} alt="" /> <span>Account</span></button>
                    <button className='clsprofileLogout' onClick={(e) => { e.preventDefault(); navigateManage_Campaign(); }}><img src={user_account} alt="" /> <span>Manage</span></button>
                    <button className='clsprofileLogout'><img src={user_logout} alt="" /> <span>Help</span></button>
                    <button className='clsprofileLogout' onClick={(e) => { e.preventDefault(); handleLogout_clear(); }}><img src={user_profile} alt="" /> <span>Logout</span></button>
                  </div>
                ) : ""}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserNav;