import React, { useState } from 'react';
import Parallax from "react-parallax-scroll";
import { reset_home, white_arrow,mailicon} from '../assets/images/landing';
import Homenav from '../components/Navbar/Homenav';
import InputField from '../components/Input';
import { useDispatch } from 'react-redux';
import { resetPasswordUser } from '../redux/actions';
import { Formik } from 'formik';
import { resetpasswordValidation } from '../constants';
const ResetPassword = () => {
  const dispatch = useDispatch();
  const [currentStep, SetcurrentStep] = useState(1);
  const [emailerr_values, setEmailErrValues] = useState(false);
  const handleSubmit = async(values) => {
    let username = values.username;
    let result = await dispatch(resetPasswordUser({username}));
    if(result?.payload === "succeeded") {
      SetcurrentStep(2);
      setEmailErrValues(false);
    }
    else if(result?.payload === "failed")
    {
      SetcurrentStep(1);
      setEmailErrValues(true);
    }
    else {
      SetcurrentStep(1);
    }
  };
  return (
    <Parallax>
      <div className='register_bg'>
        {currentStep && currentStep === 1 && (
          <div className='row'>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 leftbox_bg">
              <header className="header-area header-area ">
                <Homenav modalView="partial"></Homenav>
                <div className="header-content-area header-content-area ">
                  <div className="container">
                    <div className="row register_row">
                      <Formik
                        initialValues={{ username: '' }}
                        validationSchema={resetpasswordValidation}
                        onSubmit={(values, { resetForm }) => {
                          handleSubmit(values);
                          resetForm({ values: "" });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isValid
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className='clsbigfont4'>Reset Password</div>
                            <div className="row clsreset_rows">
                              <div className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>
                                <div className='clsreset_content'>Enter the email associated with your account and we’ll send an email with instructions to reset your password.</div>
                                <InputField name="username" type="text" cssclass="clsinput clsinput_reset" placeholder="" label="E-Mail address"
                                  onBlur={handleBlur} onChange={handleChange}
                                  value={values.username} error={errors.username && touched.username ? errors.username : undefined} />
                              </div>
                            </div>
                            <div className="row clsbtn_rowreset">
                              <div className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>
                              <div className='clsError'>{emailerr_values ? "Email doesn't exist - Please register" : ""}</div>
                                <button type='submit' className='login_btn'>Resend <img src={white_arrow} disabled={!isValid} alt="White Arrow" /></button>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 rightbox_bg">
              <img src={reset_home} className="reset_home" alt="Logo" />
            </div>
          </div>
        )}
        {currentStep && currentStep === 2 && (
          <div className='row'>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 leftbox_bg clsmailsend">
              <header className="header-area header-area ">
                <Homenav modalView="partial"></Homenav>
              </header>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 rightbox_bg clsmailsend">
              <div className='clsmail_boxcover'>
                <div className='clsmail_box'>
                  <div className='clsmail_img'>
                    <img src={mailicon} alt="Logo" />
                  </div>
                  <div className='clsmail_head'>
                    Check your mail
                  </div>
                  <div className='clsmail_content'>
                    We have sent a password recover instructions to your email.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Parallax>
  )
}
export default ResetPassword;