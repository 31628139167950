import React, { useState, useEffect } from 'react';
import Parallax from "react-parallax-scroll";
import { useHistory } from "react-router-dom";
import { end_icon,edit_icon, manage_copy, manage_create, manage_delete, manage_dropdown, manage_filter, manage_folder } from '../../assets/images/landing';
import Usernav from '../../components/Navbar/Usernav';
import { useDispatch } from 'react-redux';
import { getManageCampaign, deleteCampaign, duplicateCampaign, endCampaign } from '../../redux/actions';
import $ from "jquery";
import Swiper from 'swiper';
import Select from '../../components/Select';
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';

const ManageCampaign = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sliderData, setSliderData] = useState([]);
    const [sliderLoad, setSliderLoad] = useState(false);
    const [errorMsgLoad, setErrorMsgLoad] = useState(false);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState();
    const [menutoggle, SetMenutoggle] = useState(false);
    const [folderId,setFolderId] = useState();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [showMsg, setShowMsg] = useState("Successfully Completed !");
    const NavigateToCampaign = event => history.push('/campaign');

    const navigateManage_Campaign = (id) => {
        history.push('/campaign/'+id);
    }

    const delete_Campaign = async (offerId) => {
        let delete_result = await dispatch(deleteCampaign(offerId));
        if (delete_result?.payload?.status === "succeeded") {
            setDeleteModalOpen(false);
            setShowMsg("Deleted Successfully");
            setShow(true);
            setDeleteId("");
            let datas = await dispatch(getManageCampaign());
            getCampaign_datas(status);
        }
    }

    const duplicate_Campaign = async (offerId) => {
        let duplicate_result = await dispatch(duplicateCampaign(offerId));
        if (duplicate_result?.payload?.status === "succeeded") {
            setShowMsg("Copy Created Successfully");
            setShow(true);
            history.push('/campaign/'+duplicate_result?.payload?.with);
        }
    }

    const delete_Modal = (offerId) => {
        setDeleteModalOpen(true);
        setDeleteId(offerId);
    }

    const end_Campaign = async (offerId) => {
        let duplicate_result = await dispatch(endCampaign(offerId));
        if (duplicate_result?.payload?.status === "succeeded") {
            setShowMsg("Offer moved to Expired Successfully");
            setShow(true);
            let datas = await dispatch(getManageCampaign());
            getCampaign_datas(status);
        }
    }

    const handleCampaign_datas = (e) => {
        setStatus(e.target.value);
        SetMenutoggle(false);
        return getCampaign_datas(e.target.value);
    }

    const getCampaign_datas = async (statusid) => { 
        let statusval = statusid !== undefined && statusid.length > 0 ? statusid : "live";
        setErrorMsgLoad(false);
        setSliderData([]);
        let datas = await dispatch(getManageCampaign({ status: statusval }));
        setSliderData(datas?.payload?.with);
        sliderRun();
        setTimeout(() => {
            setErrorMsgLoad(true);
        }, 1000);
    }

    const sliderRun = () => {
        window.$(document).ready(function () {
            // Swiper: Slider
            new Swiper('.category-shop-slider-container', {
                direction: 'horizontal',
                speed: 1000,
                slidesPerView: 3,
                spaceBetween: 10,
                observer: true,
                observeParents: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                freeMode: true,
                parallax: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 3
                    },

                    992: {
                        slidesPerView: 2
                    },

                    768: {
                        slidesPerView: 2

                    },

                    576: {
                        slidesPerView: 1
                    },

                    0: {
                        slidesPerView: 1
                    }
                }
            });
        });
    }

    useEffect(async () => {
        setSliderLoad(true);
        setStatus("live");
        await getCampaign_datas("live");
        if (sliderData.length === 0) {
            let load_status = status;
            await getCampaign_datas(load_status);
        }
        sliderRun();
    }, [!sliderLoad]);

    const slideroptions = [{ id: 'live', name: 'Live' }, { id: 'upcoming', name: 'Upcoming' }, { id: 'expired', name: 'Expired' }, { id: 'draft', name: 'Draft' }];

    return (
        <Parallax>
            <div className='welcome_bg'>
                <div className='campaign_cover'>
                    <header className="header-area header-area ">
                        <Usernav modalView="partial" banner_title="Manage Campaigns"></Usernav>
                    </header>
                    <div className="container clsmanagecampaign_card">
                        <div className="row col-lg-12 clscampaignrow">
                            <div className="row clsmanage_rows">
                                <Select
                                    name="status"
                                    labelview="Status"
                                    label=""
                                    options={slideroptions}
                                    onChange={handleCampaign_datas}
                                    cssclass="clsfilter"
                                    cssform="clsfilterform"
                                    select_img={manage_filter}
                                    status={status}
                                />
                                <Toast className="clsbox_right" bg={"danger"} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                    <Toast.Body className='success text-green'>{showMsg}</Toast.Body>
                                </Toast>
                                {sliderData && sliderData?.length > 0 ? (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 clscampaigncol">
                                        <div className="swiper-container category-shop-slider-container">
                                            <div className="swiper-wrapper">
                                                {sliderData?.map((item, index) => (
                                                    <div className="swiper-slide">
                                                        <div className="category-item">
                                                            <div className="thumb">
                                                                <img src={item?.images[0]?.url} alt="" />
                                                            </div>
                                                            <div className="swiper-slide-layout">
                                                                <p className={item?.title?.length > 30 ? 'slider_heading_max': item?.title?.length > 0 ?'slider_heading':'slider_heading_mobile'}><button onClick={(e) => { e.preventDefault();  SetMenutoggle(folderId === index ? !menutoggle : "false"); setFolderId(index) }}>{item?.title}</button></p>
                                                                <button type='button' className='manage_folder' onClick={(e) => { e.preventDefault();  SetMenutoggle(folderId === index ? !menutoggle : "false"); setFolderId(index) }}><img src={manage_folder} alt="" /><img src={manage_dropdown} alt="" /></button>
                                                                {menutoggle ? (
                                                                    <div className={status !== ("expired" || "draft") ? 'clsfolder_view' : 'clsfolder_view clsfolder_small'} style={{display: folderId === index ? "block" : "none"}}>
                                                                        <button className='clsManageActions' onClick={(e) => { e.preventDefault(); navigateManage_Campaign(item?.id);SetMenutoggle(false); }}><img src={edit_icon} alt="" /> <span>Edit</span></button>
                                                                        <button className='clsManageActions clsduplicate' onClick={(e) => { e.preventDefault(); duplicate_Campaign(item?.id);SetMenutoggle(false); }}><img src={manage_copy} alt="" /> <span>Duplicate</span></button>
                                                                       {status !== ("expired" || "draft") ? <button className='clsManageActions' onClick={(e) => { e.preventDefault(); end_Campaign(item.id);SetMenutoggle(false); }}><img src={end_icon} alt="" /> <span>End</span></button> :""}
                                                                        <button className='clsManageActions' onClick={(e) => { e.preventDefault(); delete_Modal(item.id); SetMenutoggle(false); }}><img src={manage_delete} alt="" /> <span>Delete</span></button>
                                                                    </div>
                                                                ) : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {sliderData?.length > 3 ? <div className='swiperactions'>
                                            <div className="swiper-button-prev" onClick={(e) => { e.preventDefault();  SetMenutoggle(false)}}></div>
                                            <div className="swiper-button-next" onClick={(e) => { e.preventDefault();  SetMenutoggle(false)}}></div></div> : ""}
                                    </div>) : errorMsgLoad === false ? <div className="data-loader-bg"><div className='data-loader'></div></div> : ""}
                                {errorMsgLoad === true && sliderData?.length === 0 ? <div className='cls_norecords'><span>You have no offers</span></div> : ""}

                                <button className='clscreate' type='button' onClick={NavigateToCampaign}><span>Create New</span> <img src={manage_create} alt="" /></button>

                            </div>
                        </div>
                    </div>
                    <Modal
                        dialogClassName='delete-modal-content modal-dialog modal-dialog-centered contact_modal_inn'
                        centered
                        show={deleteModalOpen}
                        animation={true}
                        onHide={() => { setDeleteModalOpen(false);setDeleteId(""); }}
                        id="delete_modal"
                    >
                        <div className='contact-modal-contain'>
                            <div className='modal-header'>
                                <div className="section-title mt-35">
                                    Are you sure you want to delete this, click yes
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {setDeleteModalOpen(false);setDeleteId("")}}><span aria-hidden="true">x</span></button>
                            </div>
                            <div className='modal-body'>
                                <div className='row delete_btn_cancel'>
                                    <button type="button" className='clsyes_btn' onClick={(e) => { e.preventDefault(); delete_Campaign(deleteId) }}><span aria-hidden="true">Yes</span></button>
                                    <button type="button" data-dismiss="modal" aria-label="Close" onClick={() =>{setDeleteId("");setDeleteModalOpen(false)}}><span aria-hidden="true">No</span></button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className='clsCircleBg'></div>
            </div>
        </Parallax>
    )
}

export default ManageCampaign;