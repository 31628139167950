import React, { useState, useEffect } from 'react';
import Parallax from "react-parallax-scroll";
import { useHistory, useLocation } from "react-router-dom";
import { register_screen, white_arrow } from '../assets/images/landing';
import Homenav from '../components/Navbar/Homenav';
import InputField from '../components/Input';
import LocationInput from '../components/LocationInput';
import { createAccountValidation, upgradeAccountValidation } from '../constants';
import { Formik } from 'formik';
import { createUser, upgradeUser } from '../redux/actions';
import { useDispatch } from 'react-redux';
import useGoogleMapsApi from "../components/AutoCompleteGeo";
import Modal from 'react-bootstrap/Modal';
import { verifyMail } from '../redux/actions';

const Registration = () => {
  const dispatch = useDispatch();
  const [saved_values, setSavedValues] = useState(false);
  const [emailerr_values, setEmailErrValues] = useState("");
  const [homeCityNameval, setHomecityNameval] = useState("");
  const [homeCityval, setHomecityval] = useState("");
  const [homeCityLatval, setHomecityLatval] = useState("");
  const [homeCityLongval, setHomecityLongval] = useState("");
  const [homeCityPlaceval, setHomecityPlaceval] = useState("");
  const [homeCityErr, sethomeCityErr] = useState(1);
  const history = useHistory();
  const [imageLen, setImageLen] = useState("");
  const location = useLocation();
  const googleMapsApi = useGoogleMapsApi();
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [warningStatus, setWarningStatus] = useState(1);
  const [upgradeStep, setUpgradestep] = useState();
  const [upgradeStatus, setUpgradestatus] = useState();
  const [countSubmit, setCountSubmit] = useState(false);
  const [verifyStatus,setVerifystatus] = useState(false);

  useEffect(async () => {
    if (location?.state?.id !== "" && location?.state?.id !== undefined) {
      setWarningModalOpen(true);
    }
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getLocation = (name, city, lat, long, placeid) => {
    setHomecityNameval(name);
    setHomecityval(city);
    setHomecityLatval(lat);
    setHomecityLongval(long);
    setHomecityPlaceval(placeid);
  };

  const checkLocation = () => {
    var addressField = document.getElementById("location_input");
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({
      'address': addressField.value
    }, function (results, status) {
      if (status === googleMapsApi.GeocoderStatus.OK && results.length > 0) {
        //addressField.value = results[0].formatted_address;;
      } else {
        sethomeCityErr(3);
        return false;
      }
    });
  }

  const checkHomeCity = () => {
    if (!homeCityval) {
      sethomeCityErr(2);
      return false;
    }
  }

  const emailVerify = async(e) =>{
    let result = await dispatch(verifyMail(e.target.value));
    if(result.payload === 400) {
      setVerifystatus(true);
    }
    else {
      setVerifystatus(false);
    }
  }

  const upgradeUserForm = async () => {
    let result, values;
    values = upgradeStep;
    if (location?.state?.id !== "" && location?.state?.id !== undefined) {
      values.email = location?.state?.id
    }
    result = await dispatch(upgradeUser(values));
    if (result?.payload === "succeeded") {
      setSavedValues(true);
      setEmailErrValues("");
      setHomecityval("");
      setUpgradestep("");
      document.getElementById("location_input").value = "";
      goToTop();
      setTimeout(() => {
        history.push('/login',{logvia: "upgrade"});
      }, 3000);
    }
    else {
      setSavedValues(false);
      setEmailErrValues(result.payload);
      goToTop();
    }
  }

  const handleUpgrade = async (values, { resetForm }) => {
    if (!homeCityval) {
      sethomeCityErr(2);
      return false;
    }
    else {
      if (homeCityErr === 2 || homeCityErr === 3) {
        setImageLen("Please choose valid address");
        return false;
      }
      else {
        values.homeCityName = homeCityNameval;
        values.homeCity = homeCityval;
        values.homeCityLat = homeCityLatval;
        values.homeCityLon = homeCityLongval;
        values.homeCityPlaceId = homeCityPlaceval;
        values.isSubscribed = 1;
        if (location?.state === undefined) {
          setEmailErrValues("Please choose plan and register.");
        }
        else {
          values.payment_plan = location?.state?.plan;
          if (location?.state?.id !== "" && location?.state?.id !== undefined) {
            values.email = location?.state?.id
          }
          values.payment_method = "cash";
          let result = await dispatch(upgradeUser(values));
          if (result?.payload === "succeeded") {
            setSavedValues(true);
            setEmailErrValues("");
            resetForm({});
            setHomecityval("");
            document.getElementById("location_input").value = "";
            goToTop();
            setTimeout(() => {
              history.push('/login',{logvia: "upgrade"});
            }, 3000);
          }
          else {
            setSavedValues(false);
            setEmailErrValues(result.payload);       
            goToTop();
          }
        }
      }
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (!homeCityval) {
      sethomeCityErr(2);
      return false;
    }
    else {
      if (homeCityErr === 2 || homeCityErr === 3) {
        setImageLen("Please choose valid address");
        return false;
      }
      else {
        values.homeCityName = homeCityNameval;
        values.homeCity = homeCityval;
        values.homeCityLat = homeCityLatval;
        values.homeCityLon = homeCityLongval;
        values.homeCityPlaceId = homeCityPlaceval;
        values.isSubscribed = 1;
        if (location?.state === undefined) {
          setEmailErrValues("Please choose plan and register.");
        }
        else {
          values.payment_plan = location?.state?.plan;
          values.payment_method = "cash";
          setUpgradestep(values);
          let result = await dispatch(createUser(values));
          if (result?.payload === "succeeded") {
            setSavedValues(true);
            setEmailErrValues("");
            resetForm({});
            setHomecityval("");
            document.getElementById("location_input").value = "";
            goToTop();
            setTimeout(() => {
              history.push('/login',{ logvia: "business"});
            }, 3000);
          }
          else {
            setSavedValues(false);
            if (result.payload === 400) {
              setWarningModalOpen(true);
              setCountSubmit(true);
            }
            else {
              setEmailErrValues(result.payload);
            }
            goToTop();
          }
        }
      }
    }
  };

  return (
    <Parallax>
      <div className='register_bg'>
        <div className='row'>
          <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 leftbox_bg">
            <header className="header-area header-area ">
              <Homenav modalView="partial"></Homenav>
              <div className="header-content-area header-content-area ">
                <div className="container">
                  <div className="row register_row">

                    <Formik
                      enableReinitialize={true} initialValues={upgradeStatus === 1 ? { firstName: '', lastName: '', website: '', username: '', homeCity: '', email: '', phone_number: '', reference: '' } : { firstName: '', lastName: '', website: '', username: '', homeCity: '', email: '', phone_number: '', reference: '', password: '', confirmpassword: '' }}
                      validationSchema={upgradeStatus === 1 || verifyStatus ? upgradeAccountValidation : createAccountValidation}
                      onSubmit={upgradeStatus === 1 ? handleUpgrade : handleSubmit}>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isValid
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className='clsbigfont2'>{upgradeStatus === 1 ? "Upgrade" : "Register"}</div>
                          <div className='clsSaved'>{saved_values ? "Saved Successfully" : ""}</div>
                          <div className='clsError'>{emailerr_values ? emailerr_values : ""}</div>
                          <div className='clsVerify'>{verifyStatus ? "Already you have account here" : ""}</div>
                          <div className='clsregister_rows'>
                            <div className="row">
                              <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <InputField type="text" cssclass="clsinput" placeholder="" name="firstName"
                                  onBlur={handleBlur} onChange={handleChange} value={values.firstName}
                                  error={errors.firstName && touched.firstName ? errors.firstName : undefined} label="First Name" tabindex='1' />
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <InputField type="text" cssclass="clsinput" placeholder="" name="lastName"
                                  onBlur={handleBlur} onChange={handleChange} value={values.lastName} label="Last Name" tabindex='2' />
                              </div>
                            </div>
                            <div className="row">
                              <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <InputField type="text" cssclass="clsinput" placeholder="" name="username"
                                  onBlur={handleBlur} onChange={handleChange} value={values.username}
                                  error={errors.username && touched.username ? errors.username : undefined} label="Business Name" tabindex='3' />
                              </div>
                              {upgradeStatus === 1 ?
                                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                  <InputField type="text" cssclass="clsinput" placeholder="" name="email" read="true"
                                    onBlur={handleBlur} onChange={handleChange} value={location?.state?.id}
                                    error={errors.email && touched.email ? errors.email : undefined} label="Business E-Mail" tabindex='4' />
                                </div> :
                                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                  <InputField type="text" cssclass="clsinput" placeholder="" name="email"
                                    onBlur={emailVerify} onChange={handleChange} value={values.email}
                                    error={errors.email && touched.email ? errors.email : undefined} label="Business E-Mail" tabindex='4' />
                                </div>
                              }
                            </div>
                            <div className="row">
                              <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <LocationInput name="homeCity" id="location_input" parentCallback={getLocation} value={values.homeCity} edit="false" label="Address" multiple="false"
                                  onChange={e => { setFieldValue('homeCity', homeCityval); sethomeCityErr(1) }}
                                  onBlur={e => { setFieldValue('homeCity', homeCityval); sethomeCityErr(1); checkLocation(); }} tabindex='5' />
                                {homeCityErr === 2 ? <div className="error_input error_address">Required</div> : ""}
                                {homeCityErr === 3 ? <div className="error_input error_address">Please choose valid address</div> : ""}
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <InputField type="text" cssclass="clsinput" placeholder="" name="phone_number" max="11"
                                  onBlur={handleBlur} onChange={handleChange} value={values.phone_number}
                                  error={errors.phone_number && touched.phone_number ? errors.phone_number : undefined} label="Phone Number" tabindex='6' />
                              </div>
                            </div>
                            <div className="row">
                              <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <InputField type="text" cssclass="clsinput" placeholder="" name="website"
                                  onBlur={handleBlur} onChange={handleChange} value={values.website}
                                  error={errors.website && touched.website ? errors.website : undefined} label="Website URL" tabindex='7' />
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <InputField type="text" cssclass="clsinput" placeholder="" name="reference"
                                  onBlur={handleBlur} onChange={handleChange} value={values.reference} label="How did you hear about us" tabindex='8' />
                              </div>
                            </div>
                            {(upgradeStatus === 1 || verifyStatus) ?
                              <> </> : <div className="row">
                                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                  <InputField type="password" cssclass="clsinput" placeholder="" name="password"
                                    onBlur={handleBlur} onChange={handleChange} value={values.password}
                                    error={errors.password && touched.password ? errors.password : undefined} label="Password" tabindex='9' eye_img="true" />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                  <InputField type="password" cssclass="clsinput" placeholder="" name="confirmpassword"
                                    onBlur={handleBlur} onChange={handleChange} value={values.confirmpassword}
                                    error={errors.confirmpassword && touched.confirmpassword ? errors.confirmpassword : undefined} label="Confirm Password" tabindex='10' eye_img="true" />
                                </div>
                              </div>}
                            <div className="row">
                              {imageLen ? <div className='clsErrorName clsProfileError'>{imageLen}</div> : ""}
                            </div>
                          </div>
                          <div className="row clsbtn_row">
                            <div className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>
                              <button type='submit' onClick={checkHomeCity} className='register_btn'> {upgradeStatus === 1 ? "Upgrade" : "Register Now"} <img src={white_arrow} alt="White Arrow" /></button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <img src={register_screen} className="register_home1" alt="Logo" />
            <div className="rightbox_bg"></div>
          </div>
          <Modal
            dialogClassName='delete-modal-content modal-dialog modal-dialog-centered contact_modal_inn cls_change_pwd_modal'
            centered
            show={warningModalOpen}
            animation={true}
            onHide={() => { setWarningModalOpen(false); }}
            id="delete_modal"
            backdrop="static"
            keyboard={false}
          >
            <div className='contact-modal-contain'>
              <div className='modal-header'>
              </div>
              <div className='modal-body'>
                <div className="row clswarning_content">
                  {warningStatus === 1 ?
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      {location?.state?.id ? "Do you want to upgrade your account using the same email?" : "Email, already in use, would you like to upgrade to a business account ?" }
                    </div>
                    :
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      Please return to the WithYou app. You can book a call with us here to claim your business too
                    </div>}
                </div>
                {warningStatus === 1 ?
                  <div className='row clscampaignlink clscampaignmargin clsmargin_none clspassword_btn'>
                    {countSubmit === false ? <button type="button" className='clsreset_btn' onClick={(e) => { e.preventDefault(); setWarningModalOpen(false); setUpgradestatus(1); }}>Yes</button> :
                      <button type="button" className='clsreset_btn' onClick={(e) => { e.preventDefault(); setWarningModalOpen(false); upgradeUserForm(); }}>Yes</button>}
                    <button type="button" onClick={(e) => { e.preventDefault(); setWarningStatus(2); }} className='clsreset_btn'>No</button>
                  </div>
                  :
                  <div className='row clscampaignlink clscampaignmargin clsmargin_none clspassword_btn'>
                    <button type="button" onClick={(e) => { e.preventDefault(); setWarningModalOpen(false); history.push('/home'); }} className='clsreset_btn'>Ok</button>
                  </div>
                }
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Parallax>
  )
}

export default Registration;