import * as Yup from 'yup';

const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const phoneVerificationValidation = Yup.object().shape({
  phone_number: Yup.string().required('Required')
});

export const codeVerificationValidation = Yup.object().shape({
  verification_code: Yup.string().required('Required')
});

export const loginValidation = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const resetPasswordValidation = Yup.object().shape({
  email: Yup.string().required('Required')
});

export const createAccountValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  website: Yup.string().required('Required'),
  email: Yup.string().email('Please enter valid email').required('Required'),
  phone_number: Yup
  .string()
  .matches(phoneRegex, 'Please enter valid phone number')
  .required('Required'),
  password: Yup.string().required('Required')
  .matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()?'~`"|[/\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Your current password must be at least eight characters including one uppercase letter, one number, and one special character"
  ),
  confirmpassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], "Passwords don't match"),
});


export const upgradeAccountValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  website: Yup.string().required('Required'),
  phone_number: Yup
  .string()
  .matches(phoneRegex, 'Please enter valid phone number')
  .required('Required'),
});

export const otpAccountValidation = Yup.object().shape({
  verification_code: Yup.string().required('Required')
});


export const welcomeFormValidation = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  location_name: Yup.string().required('Required')
});


export const resetpasswordValidation = Yup.object().shape({
  username: Yup.string().required('Required')
});

export const confirmpasswordValidation = Yup.object().shape({
  password: Yup.string().required('Required')
  .matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()?'~`"|[/\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Your current password must be at least eight characters including one uppercase letter, one number, and one special character"
  ),
  confirm_password: Yup.string().required('Required').oneOf([Yup.ref('password'), null], "Passwords don't match"),
});


export const campaignStepOneValidation = Yup.object().shape({
  title: Yup.string().nullable().required('Campaign Title is Required'),
  startDate:Yup.date().nullable().required("Start Date is required"),
  endDate: Yup.date().min(
    Yup.ref('startDate'),
    "End Date can't be before Start Date"
  ).nullable().required("End Date is required"),
  category: Yup.string().required('Required'),
});

export const editAccountValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  phone_number: Yup
  .string()
  .matches(phoneRegex, 'Please enter valid phone number')
  .required('Required').nullable(),
  website: Yup.string().required('Required').nullable(),
  email: Yup.string().email('Please enter valid email').required('Required').nullable(),
});