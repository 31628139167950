import React, { useState } from "react";
import { eye_cross_icon, eye_icon } from '../../assets/images/landing';

const InputField = ({ eye_img, eye_class, value, label, label2, cssclass, name, placeholder, type, onChange, onBlur, error, tabindex,max,read,focusId }) => {
  const [inputtype, setInputtype] = useState(type);
  const [eyeimg, setEyeImg] = useState(eye_icon);
  const showPassword = (pass) => {
    setInputtype("text");
    setEyeImg(eye_cross_icon);
    setTimeout(function(){
    document?.getElementById(pass+"_second")?.focus();
  }, 1000);
  }
  const hidePassword = (pass) => {
    setInputtype("password");
    setEyeImg(eye_icon);
    setTimeout(function(){
      document?.getElementById(pass+"_first")?.focus();
    }, 1000);
  }
  return (
    <div className={eye_class === "true" ? "eyeform" : "form-group"}>
      {label && <label htmlFor="input-field">{label}<i>{label2}</i></label>}
      {eye_img === "true" ? inputtype === "password" ? <span class="clseye_btn"><input
        type={inputtype}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        tabIndex={tabindex}
        id={focusId+"_first"}
      /> <button className={eye_class === "true" ? "cls_confirmeye" : "cls_eye"} type="button"><img src={eyeimg} onClick={(e)=>{e.preventDefault();showPassword(focusId);}} alt="" /></button> </span> :
        <span class="clseye_btn"><input
          type={inputtype}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          tabIndex={tabindex}
          id={focusId+"_second"}
        /><button className={eye_class === "true" ? "cls_confirmeye" : "cls_eye"} type="button"><img src={eyeimg} onClick={(e)=>{e.preventDefault();hidePassword(focusId);}} alt="" /></button></span> : <input
        type={inputtype}
        value={value}
        name={name}
        className={cssclass + " form-control"}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={read}
        tabIndex={tabindex}
        maxLength={max}
        id={focusId+"_third"}
      />}
      {error && <div className={eye_class === "true" ? "error_input error_rel" : "error_input error_abs"}>{error}</div>}
    </div>
  )
};

export default InputField;