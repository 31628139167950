import React, { useState }  from "react";
import {search_icon } from '../../assets/images/landing';

const Search = ({ id, search, value, label,label2, cssclass, name, placeholder, type, onChange,onBlur, error, tabindex}) => {
  return (
<div className="form-group">
    {label && <label htmlFor="input-field">{label}<i>{label2}</i></label>}
    <input
      type="text"
      value={value}
      name={name}
      className={cssclass + " form-control"}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      tabIndex={tabindex}
      id={id}
    />
    {search === "true" ? <img src={search_icon} alt="" className="clssearch_icon" />: ""}
  </div>
  )
  };

export default Search;