import React from 'react';
import { useHistory } from "react-router-dom";
import Parallax from "react-parallax-scroll";
import {home_track,home_performance,home_promotions} from '../../assets/images/landing';
import Usernav from '../../components/Navbar/Usernav';

const Home = () => {
  const history = useHistory();
  return (
    <Parallax>
      <div className='welcome_bg'>
        <header className="header-area header-area ">
          <Usernav modalView="partial" banner_title="Welcome"></Usernav>
          <div className="container clshome_cover">
            <div className="row col-lg-12 clshomerow">
              <div className="col-lg-4 clsboxhome">
                <div className='clsbox_home'>
                <a href="javascript:void(0);" onClick={(e) => { e.preventDefault(); history.push('/campaign') }}>
                <div className='clsboximg'><img src={home_promotions} alt="Free Plan" /></div>
                <div className='clsboxhead_home'>
                    Create / Stage Promotions
                </div>
                </a>
                </div>
              </div>
              <div className="col-lg-4 clsboxhome">
              <div className='clsbox_home'>
              <a href="javascript:void(0);" onClick={(e) => { e.preventDefault(); history.push('/manage-campaign') }}>
                <div className='clsboximg clswelcomeimg'><img src={home_track} alt="Monthly Plan" /></div>
                <div className='clsboxhead_home'>
                    Track / Manage Current
                </div>
                </a>
                </div>
              </div>
              <div className="col-lg-4 clsboxhome">
              <div className='clsbox_home'>
              <a href="javascript:void(0);" onClick={(e) => { e.preventDefault(); history.push('/performance'); }}>
                <div className='clsboximg'><img src={home_performance} alt="Annual Plan" /></div>
                <div className='clsboxhead_home'>
                    Performance
                </div>
                </a>
                </div>
              </div>
            </div>
          </div>
           <div className='clsCircleBg'></div> 
        </header>
      </div>
    </Parallax>
  )
}

export default Home;