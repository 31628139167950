import React,{useState,useEffect} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Parallax from "react-parallax-scroll";
import { signin_home, white_arrow } from '../assets/images/landing';
import Homenav from '../components/Navbar/Homenav';
import InputField from '../components/Input';
import { useDispatch } from 'react-redux';
import { loginUser } from '../redux/actions';
import { Formik } from 'formik';
import { loginValidation } from '../constants';
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [err_values, setErralues] = useState("");
  const [msgLoad, setMsgLoad] = useState(false);
  const location = useLocation();
  const [loadValues, setLoadValues] = useState();
  const NavigateToPayment = event => history.push('/payment');
  const NavigateToForgot = event => history.push('/reset-password');
  useEffect(async () => {
    setLoadValues(location?.state?.logvia);
    setTimeout(() => {
      setLoadValues("");
    }, 3000);
  }, []);
  const handleSubmit = async(values) => {
    setMsgLoad(true);
    let username = values.username.trim();
    let password = values.password.trim();
    let result = await dispatch(loginUser({
      username,
      password
    }));
    if(result?.payload === "Invalid User")
    {
      setErralues("Please enter valid details");
      setMsgLoad(false);
    }
    if (result?.payload?.payload?.status === "succeeded") {
      setMsgLoad(false);
      history.push('/homeview');
    }
  };
  return (
    <Parallax>
      <div className='register_bg'>
        <div className='row'>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 leftbox_bg">
            <header className="header-area header-area ">
              <Homenav modalView="partial"></Homenav>
              <div className="header-content-area header-content-area ">
                <div className="container">
                  <div className="row register_row">
                    <Formik
                      initialValues={{ username: '', password: '' }}
                      validationSchema={loginValidation}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className='clsbigfont2'>Sign In</div>
                          <div className="row field_rows">
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                              <InputField name="username" type="text" cssclass="clsinput" placeholder="" label="E-Mail"
                                onBlur={handleBlur} onChange={handleChange}
                                value={values.username} error={errors.username && touched.username ? errors.username : undefined} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                              <InputField name="password" type="password" cssclass="clsinput" placeholder="" label="Password"
                                onBlur={handleBlur} onChange={handleChange}
                                value={values.password} error={errors.password && touched.password ? errors.password : undefined}  eye_img="true" />
                            </div>
                          </div>
                          <div className="row clsbtn_row">
                            <div className='col-lg-10 col-md-10 col-sm-10 col-xs-10'>
                            <div className='clsError'>{err_values ? err_values : ""}</div>
                            <div className='clsLogin'>{loadValues === "upgrade" ? "You can keep using the dashboard with same credentials or open the app to use the business features":""}</div>
                              <button type='submit' className='login_btn'>Sign In <img src={white_arrow} disabled={!isValid} alt="White Arrow" /></button>
                              {msgLoad === true ? <div className='login-loader'></div> : ""}
                              <button type='button' className='link_btn' onClick={NavigateToForgot}>Forgot Password?</button>
                              <button type='button' className='link_btn' onClick={NavigateToPayment}>If you dont have an Account Register Here</button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <img src={signin_home} className="register_home" alt="Logo" />
            <div className="rightbox_bg"></div>
          </div>
        </div>
      </div>
    </Parallax>
  )
}
export default Login;