import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import {withyouImage,signinarrowImage,videoclose,videoimage} from '../../assets/images/landing';
const Homenav = (props) => {
  const [contact_modal_open, setContactModalOpen] = useState(false);
  const [modalView, setModalView] = useState("");
  const history = useHistory();
  const NavigateToLogin = event => history.push('/login');
  const NavigatetoDemo = "https://meetings.hubspot.com/benbrockman13";
  const NavigateToRegister = event => history.push('/login');
  useEffect(() => {
    setModalView(props.modalView);
    if (props.modalStatus)
      setContactModalOpen(true);
  }, [props.modalStatus || props.modalOTPStatus])
  return (
    <div className="header-nav">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="navigation">
              <nav className={modalView === "partial" ? "navbar navbar-expand-lg navbar-light" : "navheader navbar navbar-expand-lg navbar-light"}> <a onClick={(e) => { e.preventDefault(); history.push('/') }} className="navbar-brand" href="#"> <img src={withyouImage} alt="Logo" /> </a>
                {modalView === "partial" ? "" : modalView === "partial_learn" ? <div className='right_nav'> <a  className="clsdemo_btn" type="button" target={"_blank"} href={NavigatetoDemo}> Book a Demo </a><button className="clssign_in" type="button"  onClick={(e) => { e.preventDefault(); history.push('/payment') }}> Register<img src={signinarrowImage} alt="Logo" /></button></div>  : modalView === "partialheader" ? <div className='right_nav'> <a  className="clsdemo_btn" type="button" target={"_blank"} href={NavigatetoDemo}> Book a Demo </a></div> : <div className='right_nav'> <a className="clsdemo_btn" type="button" target={"_blank"} href={NavigatetoDemo}> Book a Demo </a> <button className="clssign_in" type="button" onClick={NavigateToLogin}> Sign In<img src={signinarrowImage} alt="Logo" /></button></div>}
              </nav>
            </div>
          </div>
          <Modal
            dialogClassName='contact-modal-content modal-dialog modal-dialog-centered contact_modal_inn'
            centered
            show={contact_modal_open}
            animation={true}
            onHide={() => { setContactModalOpen(false) }}
            id="contact_modal"
            backdrop="static"
            keyboard={false}
          >
            <div className='contact-modal-contain'>
              <div className='modal-header'>
                <div className="section-title mt-35 ml-20">
                  <div class="video-container">
                    <iframe id="video-iframe" src="https://wiw-offers-app-offers.s3.us-west-2.amazonaws.com/sales_video/Benbrockman1+Final+HD+V2.mp4" width="100%" height="320" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { e.preventDefault();props.parentCallback(false);setContactModalOpen(false); }}><span aria-hidden="true"><img src={videoclose} alt="" /></span></button>
              </div>
              <div className='modal-body'>
                <div className='row'>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Homenav;